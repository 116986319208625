import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import FloatSelectView from "../../../components/floatSelectView/FloatSelectView";
import Input from "../../../components/input/Input";
import {
  getTicketServiceFee,
  getTicketPrice,
  getTimeFromEpoc,
} from "../../../utils/util";
import { CountryList, getTimeZoneKey } from "../../../utils/DefaultStates";
import { InitialPaymentBillingAddress } from "../../../utils/DefaultInitialValues";
import "./CustomerPaymentView.css";

const CustomerPaymentView = forwardRef((props, ref) => {
  const {
    bookingList = [],
    scheduleDetails = {},
    overallPrice = 0,
    bookingNavigationStepper = -1,
    userDetails,
    onUpdateBillingAddress,
  } = props;

  const [billingAddress, setBillingAddress] = useState(
    InitialPaymentBillingAddress
  );
  const [billingError, setBillingErrors] = useState({});

  const [isTermsChecked, setTermsChecked] = useState(false);

  const {
    streetError = false,
    cityError = false,
    postcodeError = false,
    countryError = false,
    termsError = false,
  } = billingError || {};

  const { planDetails = {} } = userDetails.catalystDetails;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const onChangeBillingAddress = (e) => {
    setBillingAddress({ ...billingAddress, [e.target.name]: e.target.value });
  };

  useImperativeHandle(ref, () => ({
    validatePaymentAddress() {
      let isStreetError = false;
      let isCityError = false;
      let isPostcodeError = false;
      let isCountryError = false;
      let isTermsError = false;

      let isFormValid = true;

      if (street1 === "") {
        isStreetError = true;
        isFormValid = false;
      }
      if (city === "") {
        isCityError = true;
        isFormValid = false;
      }
      if (postcode === "") {
        isPostcodeError = true;
        isFormValid = false;
      }
      if (country === "") {
        isCountryError = true;
        isFormValid = false;
      }

      if (!isTermsChecked) {
        isTermsError = true;
        isFormValid = false;
      }

      billingAddress.terms = isTermsChecked;

      setBillingErrors({
        ...billingError,
        streetError: isStreetError,
        cityError: isCityError,
        postcodeError: isPostcodeError,
        countryError: isCountryError,
        termsError: isTermsError,
      });

      if (isFormValid) {
        onUpdateBillingAddress(billingAddress);
      }

      return isFormValid;
    },
  }));

  const {
    street1 = "",
    city = "",
    postcode = "",
    country = "",
  } = billingAddress || {};

  return (
    <div className="dialog-body">
      <div className="container dialog_padding">
        <div className="row">
          <div className="col-lg-10 m-auto">
            <div className="payment">
              <div className="payment_left_view">
                <div className="payment_card_container">
                  <h4 className="color-primary-50 heading-4">
                    Address
                  </h4>
                  <div className="body-R color-primary-50 py-3">
                    We currently support the bank cards from VISA, Mastercard,
                    American Express, and UnionPay.
                  </div>
                </div>

                <div className="payment_card_container">
                  <div className="color-primary-50 subtitle-B">Address</div>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-6 my-2">
                        <Input
                          type="text"
                          id="street1"
                          placeholder="xxxx-xxxx-xxxx-xxxxx"
                          label="street no"
                          name="street1"
                          classVal="w-100"
                          value={street1}
                          onInputChange={(e) => onChangeBillingAddress(e)}
                        />
                        {streetError && (
                          <div className="text-danger pt-1">
                            ! Street is a required field
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6 my-2">
                        <Input
                          type="text"
                          id="postcode"
                          placeholder="xxxx-xxxx-xxxx-xxxxx"
                          label="Zip/postal code"
                          name="postcode"
                          classVal="w-100"
                          value={postcode}
                          onInputChange={(e) => onChangeBillingAddress(e)}
                        />
                        {postcodeError && (
                          <div className="text-danger pt-1">
                            ! Postal code is a required field
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 my-2">
                        <Input
                          type="text"
                          id="city"
                          placeholder="xxxx-xxxx-xxxx-xxxxx"
                          label="City"
                          name="city"
                          classVal="w-100"
                          value={city}
                          onInputChange={(e) => onChangeBillingAddress(e)}
                        />
                        {cityError && (
                          <div className="text-danger pt-1">
                            ! City is a required field
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6 my-2">
                        <FloatSelectView
                          name="country"
                          label="Country"
                          list={CountryList}
                          value={country}
                          onInputChange={(e) => onChangeBillingAddress(e)}
                        />
                        {countryError && (
                          <div className="text-danger pt-1">
                            ! Country is a required field
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="color-primary-50 mt-3">
                    <div className="d-flex mt-3">
                      <input
                        type="checkbox"
                        id="agree"
                        value={isTermsChecked}
                        onChange={(e) => setTermsChecked(!isTermsChecked)}
                        checked={isTermsChecked}
                      />
                      <label className="ps-2 color-primary-50" for="agree">
                        I accept the terms of use and &nbsp;
                        <a
                          href="https://firebasestorage.googleapis.com/v0/b/themindedclub-prod.appspot.com/o/minded-files%2FMINDED%20-%20Privacy%20Policy%20SERVICES%20PROVIDERS%20%26%20USERS%202025%20-%20ENG.pdf?alt=media&token=e0eb9bda-0427-4a74-bde1-83e876312979"
                          target="_blank"
                          className="color-primary-50 text-underlined"
                        >
                          <u>Privacy policy</u>
                        </a>
                      </label>
                    </div>
                    {termsError && (
                      <div className="text-danger pt-1">
                        ! Please accept the terms and policy
                      </div>
                    )}
                  </div>
                </div>
                <div class="payment-save-container">
                  <p class="payment-save-text">
                    Save this payment details to expedite the future checkout
                    process. Please be informed that we take extreme precautions
                    to safeguard your details.
                  </p>
                  <label class="toggle-switch">
                    <input type="checkbox" />
                    <span class="slider"></span>
                  </label>
                </div>
              </div>

              <div className="payment_right_view">
                <div className="row px-4 rounded-4 coupon w-100">
                  <div className="col-lg-12">
                    <div className="py-5">
                      <h4 className="color-primary-50 heading-4">Overview</h4>
                      <div className="subtitle-R color-primary-50">
                        {planDetails?.title}
                      </div>
                      <div className="body-R color-primary-50">
                        {planDetails?.subscriptionDesc}
                      </div>
                      {planDetails?.freeTrialDays !== 0 && (
                        <div className="body-R color-primary-50 my-2">
                          Free Trial For - {planDetails?.freeTrialDays} Days
                        </div>
                      )}

                      <div className="d-flex justify-content-between py-3">
                        <div className="body-R color-primary-50">
                          {planDetails?.featureTitle} x 1
                        </div>
                        <div className="body-B color-primary-50">
                          {planDetails?.price} EUR /{" "}
                        </div>
                      </div>

                      <hr />

                      <div className="d-flex justify-content-between py-3">
                        <div className="body-R color-primary-50">Total</div>
                        <div className="body-B color-primary-50">
                          {planDetails?.price} EUR
                        </div>
                      </div>
                      <div className="fs-6 color-primary-50">
                        (Includes VAT at the legally mandated rate)
                      </div>
                    </div>
                  </div>
                </div>
                <div class="time-remaining-container">
                  <span class="time-label">Time remaining</span>
                  <span class="time-value">12:45:00</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default CustomerPaymentView;
