import React from "react";
import Marquee from "react-fast-marquee";
import "./horizontalTicker.css";


const HorizontalTicker = ({ text, repeatCount = 2 }) => {
  const repeatedText = Array(repeatCount).fill(text);

  return (
    <Marquee gradient={false} speed={80}>
      {repeatedText.map((item, index) => (
        <div key={index} className="ticker-item">
          {item}
        </div>
      ))}
    </Marquee>
  );
};
export default HorizontalTicker;
