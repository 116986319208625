import {useState, useEffect, forwardRef, useImperativeHandle, useRef} from 'react';
import {useSelector} from 'react-redux';
import Input from '../../../../components/input/Input';
import FloatSelectView from '../../../../components/floatSelectView/FloatSelectView';
import Textarea from '../../../../components/textarea/Textarea';
import {Price_Methods_List, Ticket_Price_Method, ticketQuantityType_array, Ticket_Participant_Array, headerOption} from '../../../../utils/DefaultInitialValues';
import UpdatePackage from '../../dashboard/settings/settings-view/UpdatePackage';
import UpdatePlan from '../../dashboard/settings/settings-view/UpdatePlan';
import {pluralize} from '../../../../utils/util';
import {baseURL} from '../../../../actions/Constants';

const AddServicePriceView = forwardRef((props, ref) => {
  const dialogRef = useRef();
  const {
    serviceDetails = {},
    onUpdatePriceInfo
  } = props;

  const {
    details: loggedUser
  } = useSelector(({ loggedAdmin: { details} }) => ({ details }));

  const [priceDetails, setPriceDetails] = useState(serviceDetails?.servicePriceOptions);

  const [pricing_method, setPriceMethod] = useState("");
  const [pricing_type, setPricingType] = useState("");
  
  const [fixedPrice, setFixedPrice] = useState("");
  const [optionalPrice, setOptionalPrice] = useState("");
  const [differentPriceList, setDifferentList] = useState([]);

  const [ticketName, setTicketName] = useState("");
  const [ticketDescription, setTicketDescription] = useState("");

  const [selectedPackage, setSelectedPackage] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState([]);

  const [PackageArray, setPackageArray] = useState([]);
  const [PlanArray, setPlanArray] = useState([]);
  const [ticketQuantityType, setTicketQuantityType] = useState("limited");
  const [ticketMaxParticipant, setTicketMaxParticipant] = useState("");
  
  const [isShowPackageDialog, setShowPackageDialog] = useState(false);
  const [isShowPlanDialog, setShowPlanDialog] = useState(false);

  const [errors, setErrors] = useState({
    course_price_method_error: false,
    course_price_type_error : false,
    course_fixed_price_error : false,
    variant_package_errors : [],
    course_optional_price_error : false,
    ticketName_error : false,
    maxParticipant_error : false
  });

  useImperativeHandle(ref, () => ({

    validatePrice() {
      let isPriceMethodInvalid = false;
      let isPriceTypeInvalid = false;
      let isTicketNameInvalid = false;
      let isFixedPriceInvalid = false;
      let isOptionalPriceInvalid = false;

      let isPackagePriceInvalid = false;
      let isPlanPriceInvalid = false;
      let isTicketMaxParticipantInValid = false;

      let variantErrors = [];
      let isFormValid = true;

      const overallObj = {};

      const ticketObj = {};
      const planObj = {};
      const packageObj = {};
      
      const  newArray = [];

      if(pricing_method === "") {
        isPriceMethodInvalid = true;
        isFormValid = false;
      }

      if(pricing_method === "session" || pricing_method === "all") {
        ticketObj.pricingType = "ticket";
        
        const ticket = {};

        ticket.id = new Date().getTime();
        ticket.ticketName = ticketName;
        ticket.ticketDescription = ticketDescription;
        ticket.ticketQuantityType = ticketQuantityType;
        ticket.maxParticipant = 1;
        ticket.couponInfo = [];
        ticket.policyInfo = [];
        ticket.packageType = "Fixed";
        ticket.ticketList = [];

        if(pricing_type === "") {
          isPriceTypeInvalid = true;
          isFormValid = false;
        }

        if(pricing_type === "Fixed") {

          if(ticketName === "") {
            isTicketNameInvalid = true;
            isFormValid = false;
          }
          if(fixedPrice === "") {
            isFixedPriceInvalid = true;
            isFormValid = false;
          }

          if(isFormValid) {
            const sessionObj = {};

            sessionObj.packagePrice = fixedPrice;
            ticket.ticketList.push(sessionObj);
          }
        }

        if(pricing_type === "Variant") {
          differentPriceList.map((item, index) => {
            
            const variantObj = {};
            variantObj.packageName = (item.packageName === "");
            variantObj.packagePrice = (item.packagePrice === "");
            variantErrors.push(variantObj);
            
            if(item?.packagePrice === "" || item?.packageName === "") {
              isFormValid = false;
            }
          });

          ticket.ticketList = differentPriceList;
        }

        if(pricing_type === "Pay as you want") {
          if(optionalPrice === "") {
            isOptionalPriceInvalid = true;
            isFormValid = false;
          }

          if(isFormValid) {
            const optionalObj = {};
            
            optionalObj.packagePrice = optionalPrice;
            ticket.ticketList.push(optionalObj);
          }
        }

        if(serviceType !== "PRIVATE" && ticketQuantityType === "limited") {
          if(ticketMaxParticipant === "" || parseInt(ticketMaxParticipant) === -1 || parseInt(ticketMaxParticipant) > parseInt(maxParticipant)) {
            isTicketMaxParticipantInValid = true;
            isFormValid = false;
          }
        }

        ticketObj.tickets = ticket; 
      }

      if(pricing_method === "package" || pricing_method === "all") {
        if(selectedPackage.length === 0) {
          isPackagePriceInvalid = true;
          isFormValid = false;
        }
        
        packageObj.pricingType = "package";
        packageObj.packages = selectedPackage;
      }

      if(pricing_method === "plan" || pricing_method === "all") {
        if(selectedPlan.length === 0) {
          isPlanPriceInvalid = true;
          isFormValid = false;
        }

        planObj.pricingType = "plan";
        planObj.plans = selectedPlan;
      }

      let priceInfo = [];

      if(ticketObj?.pricingType) {
        priceInfo.push(ticketObj)
      }

      if(packageObj?.pricingType) {
        priceInfo.push(packageObj)
      }

      if(planObj?.pricingType) {
        priceInfo.push(planObj)
      }

      if(isFormValid) {
        onUpdatePriceInfo("servicePriceOptions", priceInfo);
      }

      setErrors({...errors, 
        course_price_method_error : isPriceMethodInvalid,
        course_price_type_error : isPriceTypeInvalid,
        course_fixed_price_error : isFixedPriceInvalid,
        ticketName_error: isTicketNameInvalid,
        variant_package_errors: variantErrors,
        course_optional_price_error: isOptionalPriceInvalid,
        course_price_info_error: isPackagePriceInvalid,
        course_plan_info_error: isPlanPriceInvalid,
        maxParticipant_error: isTicketMaxParticipantInValid

      });
      return isFormValid;
    }
  }
  ));

  const onUpdatePackagePriceInfo = (ticket = {}) => {
    const newArray = [...priceDetails];

    const filterInx = newArray.findIndex((item, inx) => item?.ticketDetails?.ticketName === ticket?.ticketDetails?.ticketName);
    if(filterInx !== -1) {
      newArray[filterInx] = ticket;
    } else {
      newArray.push(ticket);
    }
    
    setPriceDetails(newArray);
  }

  const addPackageItem = (val = "") => {
    const newArray = [...selectedPackage];

    const filterItem = PackageArray.filter((item, index) => item.id === val);

    const item = filterItem[0];
    item.maxParticipant = (serviceType === "PRIVATE") ? 1 : -1;
    newArray.push(item);

    setTimeout(() => {
      document.querySelector("#heading").focus();
    }, [200]);
    
    setSelectedPackage(newArray);
  }

  const addPlanItem = (val = "") => {
    const newArray = [...selectedPlan];
   
    const filterItem = PlanArray.filter((item, index) => item.id === val);
    const item = filterItem[0];
    item.maxParticipant = (serviceType === "PRIVATE") ? 1 : -1;
    newArray.push(item);
  
    setTimeout(() => {
      document.querySelector("#heading").focus();
    }, [200]);
    
    setSelectedPlan(newArray);
  }

  const removeItem = (title ="Variant", delInx = 0) => {
    let newList = [];

    switch(title) {
      case "Variant":
        newList = differentPriceList.filter((item, index) => index !== delInx);
        setDifferentList(newList);
        break;
      case "Package":
        newList = selectedPackage.filter((item, index) => index !== delInx);
        setSelectedPackage(newList);
      case "Plan":
        newList = selectedPlan.filter((item, index) => index !== delInx);
        setSelectedPlan(newList);
      default: break;
    }
  }

  const addDifferentPrice = () => {
    const diff_values = [...differentPriceList];
    diff_values.push({
      packageName: "",
      packagePrice: ""
    });
    setDifferentList(diff_values);
  }

  useEffect(() => {
    if(pricing_type === "Variant" && differentPriceList.length === 0) {
      addDifferentPrice();
    }
  }, [pricing_type]);

  const onDifferentValueUpdate = (e, index = 0) => {
    const newArray = [...differentPriceList];
    newArray[index][e.target.name] = e.target.value;
    setDifferentList(newArray);
  }

  const getAllPackages = async () => {
    const URL = `${baseURL}/minded-services/getPackagesByUserId/${loggedUser?.userId}`;

    return await fetch(URL, headerOption)
    .then(response => {
      const resp = response;

      const {status = ""} = resp;

     // setLoading(false);
      if(status === 200 || status === 201) {
        return response.json().then(res => { 
          setPackageArray(res);
        });
        
      } else {
        //setError(true)
      }
    })
    .catch((error) => {
      console.log('error getAllPackages', error);
      //setLoading(false);
      //setError(true)
    }); 
  }

  const getAllPlans = async () => {
    const URL = `${baseURL}/minded-services/getPlansByUserId/${loggedUser?.userId}`;

    return await fetch(URL, headerOption)
    .then(response => {
      const resp = response;

      const {status = ""} = resp;

     // setLoading(false);
      if(status === 200 || status === 201) {
        return response.json().then(res => { 
          setPlanArray(res);
        });
        
      } else {
        //setError(true)
      }
    })
    .catch((error) => {
      console.log('error getAllPackages', error);
      //setLoading(false);
      //setError(true)
    }); 
  }

  const handleNewPackageClick = () => {
    setShowPackageDialog(true);
  }

  const handleNewPlanClick  = () => {
    setShowPlanDialog(true);
  }

  useEffect(() => {
    getAllPackages();
    getAllPlans();
    try {
      dialogRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } finally {

    }
  }, []);

  const onUpdateSuccess = () => {
    getAllPackages();
  }

  const onUpdatePlanSuccess = () => {
    getAllPlans();
  }

  const {
    course_price_method_error = false,
    course_price_type_error  = false,
    course_fixed_price_error = false,
    variant_package_errors = [],
    course_optional_price_error = false,
    course_price_info_error = false,
    course_plan_info_error = false,
    ticketName_error = false,
    maxParticipant_error = false
  } = errors || {};

  const {
    serviceType = "",
    serviceScheduleDetails = {}
  } = serviceDetails || {};


  const {
    maxParticipant = -1
  } = serviceScheduleDetails || {};

	return (
    <div className="container dialog_padding" ref={dialogRef}>
      <div className="row">
        <div className="col-lg-6 m-auto">
          <div className="w-100 card-view bg-white flex_container_40 bg-white padding_70 border_50 my-3">
            <h5 className="heading-5 color-primary-50" id="heading" tabIndex="-1">2.1 Pricing Method</h5>
              
            <div className="mb-2 w-100">
              <FloatSelectView
                name = "pricing_method"
                label = "Method"
                list = {Price_Methods_List}
                value = {pricing_method}
                onInputChange = {e => setPriceMethod(e.target.value)} 
              />
            </div>

            {(pricing_method === "all") && <h4 className="color-primary-50 heading-6">Pricing Options</h4>}
            {(pricing_method === "session" || pricing_method === "all") && <>
              <div className="w-100">
                <FloatSelectView
                  name="pricing_type"
                  label = "Pricing Type"
                  value={pricing_type}
                  list = {Ticket_Price_Method} 
                  onInputChange = {e => setPricingType(e.target.value)} 
                />
                {course_price_type_error && <div className="text-danger pt-1">! Please add Price type details</div>}
              </div>

              {(pricing_method === "session" || pricing_method === "all") && <>
                <div className="w-100">
                  <Input
                    name="ticketName"
                    label="Ticket name"
                    placeHolder="xxx" 
                    value={ticketName}
                    onInputChange={(e) => setTicketName(e.target.value)} 
                  />
                  {ticketName_error && <div className="text-danger pt-1">! Ticket name is Invalid</div>}
                </div>
                  
                <div>
                  <label for="ticketDescription" className="color-primary-50 mb-2">Description (optional)</label>
                  <Textarea 
                    id="ticketDescription" 
                    name="ticketDescription" 
                    value={ticketDescription} 
                    type="text" className="w-100" 
                    rows="4" 
                    cols="100" 
                    placeholder="write description with max 3000 words."
                    onInputChange={(e) => setTicketDescription(e.target.value)}
                   />
                  
                </div>
              </>
              }

              {serviceType !== 'PRIVATE' && <div className="flex_container_50 w-100">
                <h5 className="heading-5 color-primary-50">Ticket Quantity</h5>
              
                <div className="d-flex w-100">
                  {ticketQuantityType_array.map((item, index) => {
                    return (
                      <div className="d-flex">
                        <input
                          id={item.label}
                          name = "ticketQuantityType"
                          type = "radio" 
                          className = "" 
                          value = {item.value}
                          disabled = {index === 1 && parseInt(maxParticipant) !== -1}
                          checked = {item.value === ticketQuantityType} 
                          onChange = {e => setTicketQuantityType(e.target.value)}
                        />
                        <label for={item.label} className="px-4 color-primary-50">
                          <div>{item.label}</div>
                        </label>
                      </div>
                    )
                  }
                )}
                </div>

                {ticketQuantityType === "limited" && 
                  <>
                    <div className="w-100">
                      <label for="services" className="heading-6 color-primary-50 my-2">Maximum Tickets</label>
                      <div className="d-flex w-100">
                        {Ticket_Participant_Array.map((item, index) => {
                          return (
                            <div className="color-secondary-90_bg me-3 rounded-2">
                              <input
                                id={item.label}
                                name = "maxParticipant"
                                type = "radio" 
                                className = "a11y participant" 
                                value = {item.value}
                                checked = {item.value == ticketMaxParticipant} 
                                onChange = {e => setTicketMaxParticipant(e.target.value)}
                              />
                              <label for={item.label} className="px-4 py-3 ">
                                <div>{item.label}</div>
                              </label>
                            </div>
                          )
                        }
                        )}
                      </div>

                      <div className="w-100 mt-3">
                      <Input
                        name="maxParticipant"
                        label="Custom"
                        placeHolder="xxx" 
                        value={ticketMaxParticipant}
                        type="number"
                        onInputChange={(e) => setTicketMaxParticipant(e.target.value)} 
                      />
                    </div>

                      {maxParticipant_error && <div className="text-danger pt-1"> ! Maximum participants should not be blank or more than seat count</div>}
                    </div>
                  </>
                }
                {ticketQuantityType !== "limited" && <div className="body-R color-primary-50">Unlimited tickets will be available</div>}  
              </div>}

              {pricing_type === "Fixed" && <div className="w-100 my-2">
                <Input
                  name="fixedPrice"
                  label="Ticket pricing (in EUR)"
                  placeHolder="xxx" 
                  value={fixedPrice}
                  type="number"
                  onInputChange={(e) => setFixedPrice(e.target.value)} 
                />
                {course_fixed_price_error && <div className="text-danger">! Please add a fixed price details</div>}
              </div>
              }

              {pricing_type === "Pay as you want" && <div className="w-100 my-2">
                <Input
                  name="optionalPrice"
                  label="Ticket pricing (in EUR)"
                  placeHolder="xxx" 
                  value={optionalPrice}
                  type="number"
                  onInputChange={(e) => setOptionalPrice(e.target.value)} 
                />
                {course_optional_price_error && <div className="text-danger">! Please add a minimum price details</div>}
              </div>

              }

              {pricing_type === "Variant" && <div className="w-100">
                {differentPriceList.map((eachRow, index) => (
                  <div className="row my-4" key={index}>
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="color-primary-50 heading-6">Price option {index + 1} </h6>
                      <div className="">
                        <button type="button" className="ax-button" onClick={() => removeItem("Variant", index)} disabled = {index === 0}>
                          <i className='bx bx-x-circle text-danger'></i>
                        </button>
                      </div>
                    </div>
                    
                    <div className="col-lg-6 col-md-12 my-1">
                      <Input
                        name="packageName"
                        label="Name"
                        placeHolder="xxx" 
                        value={eachRow.packageName}
                        onInputChange={(e) => onDifferentValueUpdate(e, index)} 
                      />
                      {variant_package_errors && variant_package_errors[index] && variant_package_errors[index]["packageName"] && <div className="text-danger pt-1">Name is a required field</div>}
                            
                    </div>

                    <div className="col-lg-6 col-md-12 my-1">
                      <Input
                        name="packagePrice"
                        label="Ticket price (in EUR)"
                        placeHolder="xxx" 
                        value={eachRow.packagePrice}
                        type="number"
                        onInputChange={(e) => onDifferentValueUpdate(e, index)} 
                      />
                      {variant_package_errors && variant_package_errors[index] && variant_package_errors[index]["packagePrice"] && <div className="text-danger pt-1">Price is a required field</div>}
                      
                    </div>
                  </div>
                  ))
                  }
                  <button className="tertiary_btn color-primary-50 my-5 w-100 capitalize" disabled={differentPriceList?.length >= 5} onClick={addDifferentPrice}>Add more pricing Options</button>
                </div>
              }
              </>
            }

            {(pricing_method === "all" || pricing_method === "package") && <h4 className="color-primary-50 heading-6">Packages</h4>}

            {(pricing_method === "all" || pricing_method === "package") && <>
              <div className="mb-2 w-100 ">
                <FloatSelectView
                  name = ""
                  label = "Select a package"
                  list = {PackageArray}
                  value = ""
                  valFieldName = "id"
                  labelFieldName = "packageName"
                  onInputChange = {e => addPackageItem(e.target.value)} 
                />
                {course_price_info_error && <div className="text-danger pt-1">! Please seleact atleast one Package</div>}
              
                <div className="my-5">
                  <button className="tertiary_btn w-100 color-primary-50 capitalize" onClick = {handleNewPackageClick}>Create new package</button>
                </div>
              </div>

              {selectedPackage.map((item,index) =>
                <div className="bg-light p-4 w-100 rounded-3">
                  <div className="container">
                    <div className="row">
                      <div className="col-10 my-1">
                        <div className="color-primary-50 caption-R py-1">Package</div>
                        <div className="subtitle-B color-primary-50 py-1">{item?.packageName}</div>
                        <div className="body-R color-primary-50">{item?.noOfSessions} {pluralize(item?.noOfSessions, "Session")} - {item?.packagePrice} {item?.priceCurrency}</div>
                        <div className="body-R color-primary-50">Validity - {item?.packageValidityDuration} {pluralize(item?.packageValidityDuration, "month")}</div>
                      </div>

                      <div className="col-2 my-2 d-flex align-items-center justify-content-end">
                        <button type="button" className="ax-button" onClick={() => removeItem("Package", index)} >
                          <i className='bx bx-x-circle text-danger'></i>
                        </button>
                      </div>
                    </div>
                    <hr />
                  </div>
                  
                </div>

              )}
               
            </>
            }

            {(pricing_method === "all" || pricing_method === "plan") && <h4 className="color-primary-50 heading-6">Plans</h4>}

            {(pricing_method === "all" || pricing_method === "plan") && <>
              <div className="w-100">
                <FloatSelectView
                  name = ""
                  label = "Select a plan"
                  list = {PlanArray}
                  value = ""
                  valFieldName = "id"
                  labelFieldName = "planName"
                  onInputChange = {e => addPlanItem(e.target.value)} 
                />
                {course_plan_info_error  && <div className="pt-1 text-danger">! Please select atleast one plan</div>}
             
                <div className="my-5">
                  <button className="tertiary_btn w-100 color-primary-50 capitalize" onClick = {handleNewPlanClick}>Create new plan</button>

                </div>
              </div>

              {selectedPlan.map((item,index) => 
                <div className="bg-light p-4 w-100 rounded-3">
                  <div className="container">
                    <div className="row">
                      <div className="col-10 my-1">
                        <div className="color-primary-50 caption-R py-1">Plan</div>
                        <div className="subtitle-B color-primary-50 py-1">{item?.planName}</div>
                        <div className="body-R color-primary-50">{item?.planSessionPerMonth} Sessions/month - {item?.planPrice} {item?.priceCurrency}</div>
                        <div className="body-R color-primary-50">Validity - {item.planValidityDuration} months</div>
                      </div>

                      <div className="col-2 my-2 d-flex align-items-center justify-content-end">
                        <button type="button" className="ax-button" onClick={() => removeItem("Plan", index)} >
                          <i className='bx bx-x-circle text-danger'></i>
                        </button>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
              )}
                
            </>
            } 
            
            {course_price_method_error && <div className="text-danger">! Please add a price method details</div>}
            
            {isShowPackageDialog && <UpdatePackage 
              isOpen = {isShowPackageDialog} 
              onUpdateSuccess = {onUpdateSuccess}
              handleClose = {() => setShowPackageDialog(false)} 
            />
            }

            {isShowPlanDialog && <UpdatePlan 
              isOpen = {isShowPlanDialog} 
              onUpdateSuccess = {onUpdatePlanSuccess}
              handleClose = {() => setShowPlanDialog(false)} 
            />
            }
            
          </div>
    		</div>
    	</div>
    </div>
	)
});

export default AddServicePriceView;