import React, {useState, useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import SelectPlanView from './SelectPlanView';
import Loading from '../../../components/loading/Loading';
import PaymentView from './PaymentView';
import PurchaseConfirmationView from './PurchaseConfirmationView';
import PayWidgetView from '../../payWidgetView/PayWidgetView';
import CustomizedSnackbars from  '../../../components/CustomizedSnackbars';

import { 
  calculateEpochTimeInThreeMonths,  
  getEachTicketTotalServiceFee,
  getEachTicketTotalPrice,
  isEmptyJson,
  isScheduleExpired
} from '../../../utils/util';

import {baseURL} from '../../../actions/Constants';

import ArrowBack from '@mui/icons-material/ArrowBack';
import brown_cancel from '../../../assets/brown_cancel.png';

import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import './purchaseDialog.css';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PurchaseDialog = (props) => {
  const {
    isOpen = false, 
    bookingNavigationStepper = -1, 
    onHandleClose, 
    onHandleScheduleNow, 
    scheduleDetails = {}, 
    selectedPackage = null
  } = props;

  const paymentRef = useRef();

  const [open, setOpen] = useState(false);
  const [stepperView, setStepperView] = useState(1);
  const [title, setTitle] = useState("");
  const [isFormValid, setFormValid] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const [bookingList, setBookingList] = useState([]);
  const [overallPrice, setOverallPrice] = useState(0);

  const [pInfo, setPurchasedInfo] = useState(null);

  const [isShowTicketError, setShowTicketError] = useState(false);
  const [ticketScheduleDetails, setTicketScheduleDetails] = useState([]);
  const [billingAddress, setBillingAddress] = useState({});
  const [avlSeatList, setAvlSeatList] = useState([]);

  const [isExpired, setExpired] = useState(false);

  const {
    details: loggedUser
  } = useSelector(({ loggedAdmin: { details} }) => ({ details }));

  useEffect(() => {
    if(bookingNavigationStepper !== -1) {
      setStepperView(bookingNavigationStepper);
    }
  }, [bookingNavigationStepper]);

  useEffect(() => {

    const {scheduleEndTime = 0, scheduleId = ""} = scheduleDetails || {};
    const isExp = (selectedPackage === null) && isScheduleExpired(scheduleEndTime)
    
    if(isExp) {
      setExpired(true);
    } else {
      if(scheduleId) {
        getAvlSeatDetails(scheduleId);
      }
    }
  }, []);

  const getAvlSlotsForId = (id = 0) => {
    const filterItem = avlSeatList.filter(item => item?.id == id);
    if(filterItem && filterItem.length) {
      const avlSeat = filterItem[0]?.availableSeat;
      if(avlSeat === 0) {
        return "Sold Out";
      } else if(avlSeat === -1) {
        return "Unlimited";
      }
      return `${avlSeat} Available`;
    }

    return "Unlimited";
  }

  const {
    scheduleId = "",
    serviceId = "",
    scheduleMode = "",
    scheduleDate = "",
    serviceDetails = {}
  } = scheduleDetails || {};

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onHandleClose();
  };

  const updateTitle = () => {
    let titleVal = "Tickets";

    switch(stepperView) {
      case 1:
        titleVal = "Tickets";
        break;
      case 2:
        titleVal = "Add payment address";
        break;
      case 3:
        titleVal = "Add payment details";
        break;
       case 4:
        titleVal = "Confirmation";
        break;
      default : break;
    }

    setTitle(titleVal);
  }

  useEffect(() => {
    updateTitle();
  }, [stepperView]);

  useEffect(() => {
    const newList = [];
    
    const newArray = serviceDetails?.servicePriceOptions;
   
    if(newArray) {
      newArray.map((eachOption, index) => {
        const ticket = {};

        if(eachOption?.pricingType === "ticket") {
          ticket.pricingType = eachOption?.pricingType;
          ticket.tickets = eachOption?.tickets;

          const list = eachOption?.tickets?.ticketList?.map((eachTck, inx) => {
            const eachObj = eachTck;
            eachObj.quantity = 0;
            eachObj.serviceFee = eachOption?.serviceFee;
            eachObj.serviceFeeType = eachOption?.serviceFeeType;
            eachObj.availableTicket = getAvlSlotsForId(eachOption?.tickets?.id);

            return eachObj;
          });

          ticket.tickets.ticketList = list;
          newList.push(ticket);
        } else if(eachOption?.pricingType === "package") {

          const list = eachOption?.packages?.map((eachTck, inx) => {
            
            const eachObj = eachTck;
            eachObj.pricingType = eachOption?.pricingType;
            eachObj.quantity = 0;
            eachObj.serviceFee = eachOption?.serviceFee;
            eachObj.serviceFeeType = eachOption?.serviceFeeType;
            eachObj.availableTicket = getAvlSlotsForId(eachTck?.id);
            newList.push(eachObj);
          });
      
        } else if(eachOption?.pricingType === "plan") {

          const list = eachOption?.plans?.map((eachTck, inx) => {
            
            const eachObj = eachTck;
            eachObj.pricingType = eachOption?.pricingType;
            eachObj.quantity = 0;
            eachObj.serviceFee = eachOption?.serviceFee;
            eachObj.serviceFeeType = eachOption?.serviceFeeType;
            eachObj.availableTicket = getAvlSlotsForId(eachTck?.id);
            newList.push(eachObj);
          });
        }

        setTicketScheduleDetails(newList);
      });
    }
  }, [avlSeatList]);

  const goNext = async () => {
    const isValid = await validate();
    
    if(isValid) {
      if(stepperView === 4) {
        handleClose();
      }

      if(stepperView === 1) {
        if(overallPrice === 0) {
          setStepperView(stepperView + 3);
        } else {
          setStepperView(stepperView + 1);
        }
      }

      else if(stepperView === 2) {
       // alert("Hi")
      } else {
        setStepperView(stepperView + 1);
      }
    }
  }

  useEffect(() => {
    if(billingAddress && !isEmptyJson(billingAddress) && billingAddress?.customer?.customerInfo?.customerEmail !== "") {
      sessionStorage.setItem("customerData", billingAddress);
      insertPurchase();
    }
  }, [billingAddress]);

  const getAvlSeatDetails = async () => {

    //setAvlSeatList(newAvlListFromServer);
          
    const URL = `${baseURL}/minded-services/getAvlSeatDetails?scheduleId=${scheduleId}&serviceId=${serviceId}`;

    setLoading(true);

    return await fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      const resp = response;
      const {status = ""} = resp;
      setLoading(false);

      if(status === 200) {
        return response.json().then(res => {
          //console.log("getAvlSeatDetails", res);
          setAvlSeatList(res);
        });
      } else {
        //Error
        setLoading(false);
        setError(true);
      }
    })
    .catch((error) => {
      //console.log('error getSchedules', error);
      console.log('error getAvlSeatDetails', error);
      setLoading(false);
      setError(true);
    });
  }

  const insertPurchase = async () => {
    const URL = `${baseURL}/minded-services/insertOrUpdatePurchase`;
    setLoading(true);
    
    const filterName =  loggedUser?.profileDescription?.publicName || loggedUser?.name;

    const purchaseInfo = {};
    purchaseInfo.userId = loggedUser?.userId;
    purchaseInfo.userName = filterName;
    purchaseInfo.serviceId = serviceId;
    purchaseInfo.purchaseDate = new Date().getTime();
    
    purchaseInfo.purchaseType = bookingList && bookingList[0] && bookingList[0]?.pricingType.toUpperCase();
    purchaseInfo.scheduleId = purchaseInfo.purchaseType === 'TICKET' ? scheduleId : "";
    purchaseInfo.purchaseDetails = [];

    let totalPrice = 0;

    bookingList.map((item, index) => {
      const obj = {};
      obj.type = item?.pricingType?.toUpperCase();
      obj.id =  item.id;

      totalPrice = getEachTicketTotalPrice(item);
      if(item?.pricingType === "ticket") {
        obj.id = JSON.stringify(item?.tickets?.id);
        item?.tickets?.ticketList?.map((eachTck, inx) => {
          obj.quantity = eachTck?.quantity;
          obj.purchaseExpiryDate = scheduleDetails?.scheduleEndTime;
          purchaseInfo.purchaseExpiryDate = obj.purchaseExpiryDate;
        });
      } else if(item?.pricingType === "package") {
        obj.quantity = 1;
        obj.purchaseExpiryDate = calculateEpochTimeInThreeMonths(item?.packageValidityDuration);
        purchaseInfo.purchaseExpiryDate = obj.purchaseExpiryDate;
      } else if(item?.pricingType === "plan") {
        obj.quantity = 1;
        obj.purchaseExpiryDate = calculateEpochTimeInThreeMonths(item?.planValidityDuration);
        purchaseInfo.purchaseExpiryDate = obj.purchaseExpiryDate;
      }
     
      purchaseInfo.purchaseDetails.push(obj);
    });

    const obj = {};
    obj.mode = scheduleMode || "Online";
    obj.serviceCategory = serviceDetails.serviceCategoryDetails?.serviceCategoryName;
    obj.serviceName = serviceDetails?.serviceName;
    obj.serviceLocation = serviceDetails?.serviceLocation;
    obj.bookingList = bookingList;
    purchaseInfo.purchaseAdditionalDetails = obj;

    purchaseInfo.purchaseAmount = totalPrice;
    purchaseInfo.transactionCurrency = "EUR";
    purchaseInfo.paymentId = "";
    purchaseInfo.authStatus = "NA";
    purchaseInfo.purchaseStatus = "pending";
    purchaseInfo.trail = [];

    console.log("insertPurchase --->", purchaseInfo);
    setPurchasedInfo(purchaseInfo);

    return await fetch(URL, {
      method: 'POST',
      body: JSON.stringify(purchaseInfo),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      const resp = response;

      const {status = ""} = resp;

      if(status === 200 || status === 201) {
        return response.json().then(res => {
          setLoading(false);
          const firstBookingItem = bookingList && bookingList[0];

          if(firstBookingItem?.pricingType === "ticket" || firstBookingItem?.pricingType === "package") {
            initiate_minded_payment_non_recur(res?.purchaseId, res?.paymentId);
          } else {
            initiate_minded_payment(res?.purchaseId, res?.paymentId);
          }
        });
      } else {
        //Error
        setLoading(false);
        setError(true);
      }
    })
    .catch((error) => {
      console.log('error insertPurchase', error);
      setLoading(false);
      setError(true);
    });
  }

  const handleBack = () => {
    setStepperView(stepperView - 1);
  }

  const handleScheduleNow = () => {
    handleClose();
    onHandleScheduleNow(bookingList);
  }

  const initiate_minded_payment = async (purchaseId = "", paymentId = "") => {
    setLoading(true);
    //const URL = `${baseURL}/minded-services/initiate_minded_payment`;
    const URL = `${baseURL}/minded-services/initiate_minded_payment_catalyst_subscription`;
    
    const filterName =  loggedUser?.profileDescription?.publicName || loggedUser?.name;

    const obj = {
      purchaseId: purchaseId,
      paymentId: paymentId,
      description: loggedUser?.profileDescription?.publicName || loggedUser?.name,
      value: parseFloat(overallPrice),
      currency:"EUR",
      customer: {
        customerInfo:{
          customerName: filterName,
          customerEmail: loggedUser?.email,
          billingAddress: {
            street1: billingAddress?.street1,
            street2: "",
            city: billingAddress?.city,
            postcode: billingAddress?.postcode,
            country: billingAddress?.country
          }
        }
      }
    }

    console.log("initiate_minded_payment_catalyst_subscription", obj);
    
    return await fetch(URL, {
      method: 'POST',
      body: JSON.stringify(obj),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      const resp = response;
     
      setError(false);
      setLoading(false);
      const {status = ""} = resp;

      if(status === 200 || status === 201) {
        return response.json().then(res => {
          const checkoutRes = res;
          //console.log("initiate_minded_payment response", res);

          console.log("initiate_minded_payment_catalyst_subscription response", res);
          sessionStorage.setItem("checkout", JSON.stringify(res));
          sessionStorage.setItem("purchaseId", purchaseId);
          sessionStorage.setItem("transactionID", res?.transactionID);
          sessionStorage.setItem("transactionSignature", res?.transactionSignature);
          sessionStorage.setItem("formContext", res?.formContext);
          sessionStorage.setItem("customerData", JSON.stringify(billingAddress));
          sessionStorage.setItem("actualAmount", parseFloat(overallPrice));
          sessionStorage.setItem("isRecurring", true);

          /*

          sessionStorage.setItem("checkout", JSON.stringify(res));
          sessionStorage.setItem("purchaseId", purchaseId);
          sessionStorage.setItem("transactionID", res?.purchaseDetails?.paymentDetails?.transactionID);
          sessionStorage.setItem("transactionSignature", res?.purchaseDetails?.paymentDetails?.transactionSignature);
          sessionStorage.setItem("formContext",res?.purchaseDetails?.paymentDetails?.formContext); 
          sessionStorage.setItem("customerData", JSON.stringify(billingAddress));
          sessionStorage.setItem("actualAmount", parseFloat(overallPrice));
          sessionStorage.setItem("isRecurring", true);
          */
          setStepperView(stepperView + 1);
        });
      } else {
        //Error
        setError(true);
      }
    })
    .catch((error) => {
      console.log('error initiate_minded_payment', error);
      setLoading(false);
      setError(true);
    });
  }


  const initiate_minded_payment_non_recur = async (purchaseId = "", paymentId = "") => {
    const URL = `${baseURL}/minded-services/initiate_minded_payment_non_recur`;
    setLoading(true);

    const filterName =  loggedUser?.profileDescription?.publicName || loggedUser?.name;

    const obj = {
      purchaseId: purchaseId,
      paymentId: paymentId,
      description: filterName || "",
      value: parseFloat(overallPrice),
      currency:"EUR",
      customer:{
        customerInfo: {
          customerName: filterName || "",
          customerEmail: loggedUser?.email,
          billingAddress: {
            street1: billingAddress?.street1,
            street2: "",
            city: billingAddress?.city,
            postcode: billingAddress?.postcode,
            country: billingAddress?.country
          }
        }
      }
    }

    console.log("initiate_minded_payment_non_recur request Data", obj);
    
    return await fetch(URL, {
      method: 'POST',
      body: JSON.stringify(obj),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      const resp = response;
     
      setError(false);
      setLoading(false);
      const {status = ""} = resp;

      if(status === 200 || status === 201) {
        return response.json().then(res => {
          const checkoutRes = res;
          console.log("initiate_minded_payment_non_recur response", res);
          sessionStorage.setItem("checkout", JSON.stringify(res));
          sessionStorage.setItem("purchaseId", purchaseId);
          sessionStorage.setItem("transactionID", res?.purchaseDetails?.paymentDetails?.transactionID);
          sessionStorage.setItem("transactionSignature", res?.purchaseDetails?.paymentDetails?.transactionSignature);
          sessionStorage.setItem("formContext",res?.purchaseDetails?.paymentDetails?.formContext);
          sessionStorage.setItem("customerData", JSON.stringify(billingAddress));
          sessionStorage.setItem("actualAmount", parseFloat(overallPrice));
          sessionStorage.setItem("isRecurring", false);
          setStepperView(stepperView + 1);
        });
      } else {
        //Error
        setError(true);
      }
    })
    .catch((error) => {
      console.log('error initiate_minded_payment_non_recur', error);
      setLoading(false);
      setError(true);
    });
  }

  const validate = () => {
    let isFormValid = true;

    if(stepperView === 1) {
      if(bookingList?.length === 0) {
        isFormValid = false;
        setShowTicketError(true);
      } else {
        setShowTicketError(false);
      }
    } else if(stepperView === 2) {
      isFormValid = paymentRef.current.validatePaymentAddress();
    }

    setFormValid(isFormValid);

    return isFormValid;
  }

  useEffect(() => {
    let totalPrice = 0;
    let totalServiceFee = 0;

    bookingList.map((item, index) => {
      totalPrice += parseFloat(getEachTicketTotalPrice(item));
      totalServiceFee += parseFloat(getEachTicketTotalServiceFee(item));
    });

    const overallPrice = totalPrice + totalServiceFee;
    setOverallPrice(overallPrice);
  }, [bookingList]);
  
  const onHandleAcountCheckboxChange = (name, val) => {
   // setBookingDetails({...bookingDetails, [name]: val});
  }

  const onTicketSelection = (ticket, name, quantity, inx) => {
    const newArray = [...bookingList];
    let index = -1;

    if(ticket.pricingType === "ticket") {
      index = newArray && newArray.findIndex(object => {
        return object.tickets?.id === ticket?.tickets?.id;
      });
    } else if(ticket.pricingType === "package" || ticket.pricingType === "plan") {
      index = newArray && newArray.findIndex(object => {
        return object?.id === ticket?.id;
      });
    }

    let obj = ticket;

    if(ticket?.pricingType === "ticket") {
      obj.tickets.ticketList[inx].quantity = quantity;
    } else {
      obj.quantity = quantity;
    }

    if(index === -1) {
      newArray.push(obj);
    } else {
      if(quantity === 0) {
        if(obj?.pricingType === "ticket") {
          const nonZeroList = obj.tickets.ticketList.filter((itm, i) => itm.quantity !== 0);
          if(nonZeroList && nonZeroList.length === 0) {
            newArray.splice(index, 1);
          }
        } else {
          newArray.splice(index, 1);
        }
      } else {
        newArray[index] = obj;
      }
    }

    const filterItemIndex = ticketScheduleDetails.findIndex((item, i) => item?.pricingType === ticket?.pricingType && item?.id === ticket?.tickets?.id);

    if(filterItemIndex !== -1) {
      const newScheduleDetails = [...ticketScheduleDetails];
      newScheduleDetails[filterItemIndex] = obj;
      setTicketScheduleDetails(newScheduleDetails);
    }
  
    setBookingList(newArray);
  }

  return (
    <div className="full-dialog">
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >

        <DialogActions className="w-100 p-4 mt-4">
          <div className="w-100 d-flex justify-content-between align-items-center px-0 px-lg-5 px-md-3">
            <div>
              {(stepperView === 2 && bookingNavigationStepper === -1) && <button
                onClick={handleBack}
                aria-label="Back"
                className="ax-button color-primary-50"
              >
                <ArrowBack />
              </button>
              }
            </div>

            <h4 className="heading-4 color-primary-50">
              {title}
            </h4>

            <div>
              <button
                onClick={handleClose}
                aria-label="close"
                className="ax-button"
              >
                <img src={brown_cancel} className="close_icon" alt = "Close" />
              </button>
            </div>
            
          </div>
        </DialogActions>

        <DialogContent>
          {!isExpired && stepperView === 1 && 
            <SelectPlanView
              ticketDetails = {ticketScheduleDetails}
              onTicketSelection = {onTicketSelection}
              isShowTicketError = {isShowTicketError}
              selectedPackage = {selectedPackage}
              bookingList = {bookingList}
            />
          }

          {stepperView === 2 && 
            <PaymentView
              ref = {paymentRef}
              bookingList = {bookingList}
              scheduleDetails = {scheduleDetails}
              overallPrice = {overallPrice}
              bookingNavigationStepper = {bookingNavigationStepper}
              onHandleAcountCheckboxChange = {(e, val) => onHandleAcountCheckboxChange(e, val)}
              onUpdateBillingAddress = {(data) => setBillingAddress(data)}
            />
          }

          {stepperView === 3 && 
            <PayWidgetView />
          }

          {stepperView === 4 && 
            <PurchaseConfirmationView 
              onHandleScheduleNow = {() => handleScheduleNow()}
              bookingDetails = {pInfo}
              handleClose = {handleClose}
            />
          }

          {isExpired && <div className="text-center color-primary-50 body-R ">Oh no. This schedule is already expired!</div>}
        </DialogContent>

        <DialogActions className="dialog_footer d-flex justify-content-between align-items-center bg-white">
          <div className="container-fluid">
            <div className="card-container d-flex justify-content-between align-items-center dialog_padding">
              <div className="d-flex justify-content-between align-items-center">
                {(overallPrice !== 0 && stepperView !== 3) && <div className="px-3 subtitle-R color-primary-50">Total</div>}
                {(overallPrice !== 0 && stepperView !== 3) && <div className="px-2 heading-4 color-primary-50">{overallPrice} EUR</div>}
              </div>
              <div className="d-flex">
                {stepperView <= 2 && <button disabled={isExpired} className="mx-3 secondary_btn capitalize" onClick={goNext}>{stepperView === 2 ? "Place an order" : "Next"}</button>}
                {stepperView === 4 && <button className="mx-3 secondary_btn capitalize" onClick={goNext}>Close</button>}
              </div>
            </div>
          </div>
        </DialogActions>
      </Dialog>
      {isLoading && <Loading />}
      {isError && <CustomizedSnackbars isShow={isError} text="Something went wrong. Please try after sometime." showAlert={(isHide) => setError(false)} />}      
    </div>
  );
}


export default PurchaseDialog;