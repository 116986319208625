import React from "react";
import GrowthJourney from "../../start-here/GrowthJourney";

const CustomerSelectPlan = ({ onHandlePlanDetailsChange , userDetailsPlanId}) => {
  return (
    <div>
      <GrowthJourney
        onHandlePlanDetailsChange={onHandlePlanDetailsChange}
        showSaveMore={false}
        showTitle={false}
        userDetailsPlanId={userDetailsPlanId}
      />
    </div>
  );
};

export default CustomerSelectPlan;
