import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useParams } from 'react-router-dom';

import AuthDialog from '../../components/authenticate/AuthDialog';
import CustomizedSnackbars from  '../../components/CustomizedSnackbars';
import ClassListView from '../../components/classListView/ClassListView';
import DialogView from '../../components/dialog/DialogView';
import FilterView from '../../components/filters/FilterView';
import Loading from '../../components/loading/Loading';

import HelmetDetails from "../../components/HelmetDetails";
import HelmetText from '../../components/helmet-text/HelmetText';
import PurchaseDialog from './servicePurchaseDialog/PurchaseDialog';
import ScheduleDialog from './scheduleDialog/ScheduleDialog';

import MapView from '../../components/map/MapView';
import TabView from '../../components/tabView/TabView';
import ImageCarousel from '../../components/image-carousel/ImageCarousel';
import {getTimeZoneKey} from '../../utils/DefaultStates';
import {generateEventsDropdownList, getMinimumTicketPrice, getDateFromEpoc} from '../../utils/util';
import {headerOption} from '../../utils/DefaultInitialValues';

import {getServices} from '../../actions/services.actions';

import pic_1 from '../../assets/avatar.png';
import service_placeholder from '../../assets/img_placeholder.png'; 
import location_pin from '../../assets/Location pin.png';
import {baseURL} from '../../actions/Constants';
import {saveAdmin} from '../../actions/admin.actions';

const ServiceDetails = (props) => {
	const dispatch = useDispatch();
	const saveAdminAction = saveAdmin;

	const [allDropdownList, setAllDropdownList] = useState({});
	const [isShowDialog, setShowDialog] = useState(false);
	const [isShowLoginDialog, setShowLoginDialog] = useState(false);
	const [isShowScheduleDialog, setShowScheduleDialog] = useState(false);

	const [startTimeEpoc, setStartTimeEpoc] = useState(new Date().getTime());
	const [endTimeEpoc, setEndTimeEpoc] = useState("");
	
	const [isLoading, setLoading] = useState(false);
	const [serviceDetails, setServiceDetails] = useState({});
	const [scheduleDetails, setScheduleDetails] = useState({});
	const [ticketSchedules, setTicketSchedules] = useState([]);
	const [filterTicketSchedules, setFilterTicketSchedules] = useState([]);
	const [activeTab, setActiveTab] = useState(0);

	const [catalystDetails, setCatalystDetails] = useState({});
	const [purchaseId, setPurchaseId] = useState("");
	const [isShowAboutDialog, setShowAboutDialog] = useState(false);

	const [isError, setError] = useState(false);
	const { name:scID } = useParams();
	const [isBookBtnDisabled, setBookBtnDisabled] = useState(false);

	const getServicesAction = getServices;

	const [selectedFilters, setSelectedFilters] = useState({
		selectedCatalyst : [],
		selectedLocation: [],
		selectedCategory : []
	});

	const {
    	details: loggedUser
  	} = useSelector(({ loggedAdmin: { details} }) => ({ details }));

  	const {
       details,
       loading,
       error
    } = useSelector(({ services: { details, loading, error} }) => ({ details, loading, error }));

	const getCatalystById = async (id = "") => {
		setLoading(false);
		const URL = `${baseURL}/minded-user/getUserById/${id}`;

       	return await fetch(URL, headerOption)
      	.then(response => {
		    const resp = response;

		    const {status = ""} = resp;
		    setLoading(false);

		    if(status === 200 || status === 201) {
		    	return response.json().then(res => { 
		       	setCatalystDetails(res);
		    	});
		    } else {
		    	console.log('error getCatalystById', error);
	      		setError(true);
		    }
		})
	  	.catch((error) => {
	    	console.log('error getServiceById', error);
		    setError(true);
		});
	}

	const onLoginSuccess = (isSignupView, userinfo) => {
		dispatch(saveAdminAction(userinfo));
		setShowDialog(true);
	}

	const onHandleScheduleNow = (purchaseInfo = {}) => {
		setShowScheduleDialog(true);
	}

	const getSchedulesByDate = async (startDateEpoc = 0, endDateEpoc = 0) => {
    	setLoading(true);
		const URL = `${baseURL}/minded-services/getScheduleDetails?serviceId=${scID}&serviceType=${serviceType}&scheduleStartDate=${startDateEpoc}&scheduleEndDate=${endDateEpoc}`;

		console.log("getSchedulesByDate --->", URL);
       	return await fetch(URL)
      	.then(response => {
		    const resp = response;

		    const {status = ""} = resp;
		    setLoading(false);

		    if(status === 200) {
		      return response.json().then(res => {
		      	console.log("getSchedulesByDate --->", res)
		        setTicketSchedules(res);
		      });
		    } else {
		      //Error
		      setLoading(false);
		      setError(true);
		    }
  		})
  		.catch((error) => {
		  	setLoading(false);
		  	setError(true);
		    console.log('error getSchedulesByDate', error);
		});
    }

    useEffect(() => {
    	if(Array.isArray(ticketSchedules)) {	
    		const obj = generateEventsDropdownList(ticketSchedules);
    		setAllDropdownList(obj);
    		applyFilter();
    	}
    }, [ticketSchedules]);

    useEffect(() => {
    	applyFilter();
    }, [selectedFilters]);

    const applyFilter = () => {
  
    	const { selectedCatalyst = [], selectedLocation = [], selectedCategory = [] } = selectedFilters || {};

    	let filterArray = ticketSchedules;

    	if(selectedCatalyst.length) {
    		//filterArray = ticketSchedules.filter((item, index) => selectedCatalyst.includes(item?.serviceDetails?.userDetails?.userId));
    	}

    	if(selectedCategory.length) {
    		//filterArray = filterArray.filter((item, index) => selectedCategory.includes(item?.serviceDetails?.serviceCategoryDetails?.serviceCategoryId));
    	}

    	if(selectedLocation.length) {
    		filterArray = filterArray.filter((item, index) => selectedLocation.includes(item?.serviceDetails?.serviceLocation?.city));
    	}

    	setFilterTicketSchedules(filterArray);
    }

    console.log("filterTicketSchedules --->", filterTicketSchedules)

	useEffect(() => {
    	if(Array.isArray(details)) {
    		if(details.length > 0) {
    			const srv = details[0];
    			setServiceDetails(srv);
    			getCatalystById(srv?.userDetails?.userId);
    		}
    	}
    }, [details]);

	useEffect(() => {
		window.scrollTo({top: 0, behavior: "smooth"});
		dispatch(getServicesAction("", scID, "",  "",  "",  "",  ""));
	}, []);

    const {
    	name = "",
    	profileDescription = {}
    } = catalystDetails || {};

    const {
    	aboutMe = "",
    	publicName = "",
    	profilePic = ""
    } = profileDescription || {};

    const onHandleBookNow = () => {
    	getScheduleById();
    }

    const getScheduleById = async () => {
    	let findActiveScheduleIndex = 0;

    	if(Array.isArray(details)) {
			findActiveScheduleIndex = details?.[0].scheduleDetails.findIndex(item => item?.scheduleStatus === "active");
    	}

    	if(findActiveScheduleIndex !== -1) {
    		setBookBtnDisabled(false);
			const URL = `${baseURL}/minded-services/getScheduleDetails?scheduleId=${serviceDetails?.scheduleDetails[findActiveScheduleIndex]?.scheduleId}`;
			setLoading(true);
	 		
	      	return await fetch(URL)
	      	.then(response => {
			    const resp = response;

			    const {status = ""} = resp;
			    setLoading(false);

			    if(status === 200 || status === 201) {
			    	return response.json().then(res => {
			       		setScheduleDetails(res[0]);
			       		if(isUsedLoggedIn) {
	    					setShowDialog(true);
				    	} else {
				    		setShowLoginDialog(true);
				    	}
			    	});
			    } else {
			    	console.log('error getScheduleById', error);
		      		setError(true);
			    }
			})
		  	.catch((error) => {
		    	console.log('error getScheduleById', error);
			    setError(true);
			});
	  	} else {
	  		setBookBtnDisabled(true);
	  	}
    }

    const onCheckboxSelect = (name, val) => {
    	setSelectedFilters({...selectedFilters, [name] : val});
    }

    const handleDateChange = (stDate, edDate) => {
    	if(stDate && edDate) {
	    	const startDateEpoc = new Date(stDate).getTime();
	    	const endDateEpoc   = new Date(edDate).getTime();

	    	setStartTimeEpoc(startDateEpoc);
	    	setEndTimeEpoc(startDateEpoc);
	    	getSchedulesByDate(startDateEpoc, endDateEpoc);
    	}
    }

    const TabPanel = (service_Type = "CLASS") => {
		return (
			<>
				<div className="container-fluid my-3">
					<FilterView 
						onHandleDateChange = {handleDateChange} 
						allDropdownList = {allDropdownList}
						onCheckboxSelect = {onCheckboxSelect} 
					/>
				</div>
			
				<ClassListView 
					onHandleCTAClick = {(schedule) => onHandleCTAClick(schedule)}
					serviceList = {filterTicketSchedules}
					service_Type = {service_Type}
					isCTADisabled = {loggedUser && loggedUser?.role && (loggedUser?.role === "admin" || loggedUser?.role === "catalyst")}
				/>
			</>
		);
	}

	const onHandleCTAClick = (schedule = {}) => {
		const newSchedule = schedule;
		setScheduleDetails(newSchedule);
		if(isUsedLoggedIn) {
	    	setShowDialog(true);
    	} else {
    		setShowLoginDialog(true);
    	}

	}

    const minTicketPrice = getMinimumTicketPrice(serviceDetails);

    const isUsedLoggedIn = loggedUser && loggedUser?.userId;

    const {
    	startTime = "",
    	endTime = "",
    	serviceType = "",
		serviceName = "",
		scheduleMode = "online",
		serviceStartDate = 1707216215,
		serviceEndDate = 1707216215,
		scheduleMaxParticipants = 10,
		serviceLocation = {},
		serviceDescription = "",
		serviceImg = [],
		serviceScheduleDetails = {},
		serviceTimezone = ""
    } = serviceDetails || {};

    const feature_array = catalystDetails && catalystDetails?.catalystDetails?.serviceCategory && catalystDetails?.catalystDetails?.serviceCategory?.map((item) => item?.name);
    const descLength = aboutMe ? aboutMe.length : 0;

	return (
		<>
			<HelmetDetails title="Service Details | Minded." />
			<HelmetText H1text = {serviceName || ""} />
			<div className="main-no-content-padding">

				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-8">
							
							<div className="urban-card color-secondary-90_bg rounded-3 p-lg-5 p-3">
								<div className="row w-100">
									<div className="col-6 col-md-6">
										<div>
											<div className="py-1">
												<div className="color-primary-50 body-R py-1">Date</div>
												<div className="color-primary-50 subtitle-R">{getDateFromEpoc(serviceStartDate)} <span className="body-R">to</span>  {getDateFromEpoc(serviceEndDate)}</div>
											</div>

											<div className="color-primary-50 body-R py-1">Timing</div>
											{serviceType !== "PRIVATE" &&  <div className="color-primary-50 subtitle-R">{startTime} - {endTime} {getTimeZoneKey(serviceTimezone)}</div>}
											{serviceType === "PRIVATE" && serviceScheduleDetails?.serviceScheduleTypeConfig.map((eachDate, index) => {
												return (
													
													<>
														<div className="color-primary-50 body-B">Every {eachDate?.day}</div>
														{eachDate?.dayWiseTimeList?.map((daywiseTime, index) => 
															<div>
																<div className="">
																	<div className="body-CAP capitalize py-2 color-primary-50">{daywiseTime?.startTime} - {daywiseTime?.endTime || "-"} {getTimeZoneKey(serviceTimezone)}</div>
																</div>
															</div>
														)}
													</>
												)}
											)}
											
										</div>
									</div>
									<div className="col-6 col-md-6">
										<div>
											<div className="color-primary-50 body-R py-1">Event Mode</div>
											<div className="color-primary-50 body-R py-1">{serviceScheduleDetails?.serviceMode?.toUpperCase()}</div>
											
											{serviceScheduleDetails?.serviceMode === "physical" && 
												<div className="color-primary-50 subtitle-R">
													<div className="color-primary-50 body-R py-1">Event at</div>
													<img src={location_pin} style={{width: '24px', height: '24px'}} alt = "" />
													{serviceLocation?.venueName ? `${serviceLocation?.venueName}, ${serviceLocation?.address?.label}. ` : serviceLocation?.address?.label}
												</div>
											}

											{/*
											serviceScheduleDetails?.serviceMode === "online" && <div className="color-primary-50 subtitle-R">
												Online
											</div>
											*/}
										</div>
									</div>
								</div>

								<div className="row w-100 align-items-center">
									<div className="col-lg-6 col-md-6 mt-4">
										<div>
											<div className="color-primary-50 body-R py-1" >Ticket from</div>
											<div className="color-primary-50 subtitle-R">{minTicketPrice || "-"}</div>
										</div>
									</div>
									<div className="col-lg-6 col-md-6 mt-4">
										<div className="w-100 d-flex align-items-center">
											<a href="#schedule" className="w-xs-100 secondary_btn rounded-pill" >Choose Date</a>
										</div>
									</div>
								</div>
							</div>

							<div className="urban-card my-4 bg-white rounded-3 p-lg-5 p-3">
								<div className="w-100">
									<div className="w-100">
										<h4 className="heading-4 color-primary-50">About</h4>
									</div>
								</div>
								
								<div className="w-100 responsive_flex my-2 justify-content-between my-2">
									<div className="w-100 event_description_container">
										<div className="py-3 body-R color-primary-50">{serviceDescription || "-"}</div>
										
										{/*<button className="tertiary_btn my-2 color-primary-50">READ MORE</button>*/}
									</div>

									<div className="w-100 px-2 event_description_container">
										{serviceImg.length !== 0 && <ImageCarousel images={serviceImg} />}
										{serviceImg.length === 0  && <img src = {service_placeholder} className="w-75 m-auto p-2" alt = "" />}
									</div>
								</div>
							</div>
						</div>

						<div className="col-lg-4">
        					<div className="m-auto">
								<div className="w-100 text-center">
									<img src={profilePic || pic_1} className="w-100 rounded-3" style={{maxHeight: "400px"}} alt="" />
									<div className="pt-3 body-B color-primary-50">{feature_array && feature_array.length > 0 && feature_array.join(" | ") || "-"}</div>
									<div className="body-CAP py-1 color-primary-50">{publicName || name}</div>
									
									<div className="text-justify px-3 text-justify">
										<div className="color-primary-50 body-R py-2 text-justify">
											{descLength > 100 ? aboutMe.slice(0, 100) : aboutMe}
											{descLength > 100 && <span>...</span>}

										</div>
										{descLength > 100 && <div className="d-flex justify-content-center"><button className="tertiary_btn my-2 color-primary-50" onClick={() => setShowAboutDialog(true)}>
											Read More
										</button></div>
										}
									</div>
								</div>
        					</div>
						</div>

					</div>
				</div>
				
				{scheduleMode === "physical" && <div className="container-fluid">
					<MapView serviceLocation = {serviceLocation} />
				</div>}
			</div>

			{isShowLoginDialog && <AuthDialog 
				isOpen = {isShowLoginDialog} 
				onHandleClose = {(val) => setShowLoginDialog(false)} 
				onLoginSuccess = {(isSignupView, userinfo) => onLoginSuccess(isSignupView, userinfo)}
			/>}

			{isShowDialog && <PurchaseDialog 
				isOpen = {isShowDialog}
				scheduleDetails = {scheduleDetails} 
				onHandleClose = {() => setShowDialog(false)} 
				onHandleScheduleNow = {(bookinginfo) => onHandleScheduleNow(bookinginfo)}
			/>}

			{isShowScheduleDialog && <ScheduleDialog 
				isOpen = {isShowScheduleDialog} 
				onHandleClose={() => setShowScheduleDialog(false)}
				purchaseId = {purchaseId}
			/>}

			<div className="main-content-padding pt-0" id="schedule">
				<TabView 
					children = {(service_Type) => TabPanel(serviceType)} 
					tabs = {[]} 
					onTabClick = {(val) => setActiveTab(val)}
					service_Type = {serviceType}
				/>
			</div>

			{isShowAboutDialog && <DialogView onHandleClose = {() => setShowAboutDialog(false)} show ={isShowAboutDialog} about = {profileDescription?.aboutMe} />}
			{isLoading && <Loading />}
			{isError && <CustomizedSnackbars isShow={isError} text="Something went wrong. Please try after sometime." showAlert={(isHide) => setError(false)} />}
		</>
	)
}

export default ServiceDetails;