import React, {useEffect} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const SelectwithoutLabel = (props) => {
  const {list = [], value = "", onInputChange, isDisabled = false, isShowNone = true} = props;

  const [val, setval] = React.useState('');

  const handleChange = (event) => {
    setval(event.target.value);
    onInputChange(event.target.value);
  };

  useEffect(() => {
    setval(value);
  }, [value]);

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <Select
          disabled={isDisabled}
          value={val}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {isShowNone && <MenuItem value="">
            <em>None</em>
          </MenuItem>}
          {list.map((item, index) => <MenuItem value={item.value}>{item.label}</MenuItem>)}
        </Select>
      </FormControl>
    </div>
  );
}

export default SelectwithoutLabel; 
