import {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import WeekCard from './WeekCard';
import {dayWiseScheduleArray, getFormmatedServiceName} from '../../utils/util';

const WeekViewLayout = (props) => {
	const navigate = useNavigate();
	
	const {
		indexval = 0, 
		onHandleItemClick,
		onHandleCTAClick,
		serviceList = [], 
		currentDate = "", 
		activeTab = -1, 
		CTALabel = "", 
		isCTAShow = true,
		service_Type = "",
		isCTADisabled = false,
		isShowMeetingLink = false,
		isShowParticipantCount = false
	} = props;

	const date_wise_array = dayWiseScheduleArray(serviceList, currentDate);

	const onHandleEventBookClick = (schedule) => {
	    onHandleCTAClick(schedule);
	}

	return (
		<div className="w-100 h-100 my-1 d-flex flex-column px-1 pt-1" >
			{date_wise_array.length !== 0 && date_wise_array.map((eachCls, indexVal) => {
				return (
					<WeekCard 
						item = {eachCls} 
						key = {indexVal}
						onHandleItemClick = {onHandleItemClick} 
						onHandleCTAClick = {(service) => onHandleCTAClick(service)} 
						activeTab = {activeTab}
						CTALabel = {CTALabel}
						isCTAShow = {isCTAShow}
						service_Type = {service_Type}
						isCTADisabled = {isCTADisabled}
						isShowMeetingLink = {isShowMeetingLink}
						isShowParticipantCount = {isShowParticipantCount}
					/>
				)}
			)}
			{date_wise_array.length === 0 && 
				<div 
					className="h-100 my-1 no-class-container py-lg-3 py-2 px-3 text-center d-flex align-items-center justify-content-center"
				>
					<span className="opacity-0">No {getFormmatedServiceName(service_Type)}</span>
				</div>
			}
		</div>
	)
}

export default WeekViewLayout;