const ServiceTypeArray = [
	{
	 	title :"1:1 Session",
	 	details: "One-on-One sessions booked based on availability.",
	 	icon: "",
    value : "PRIVATE"
	},
	{
	 	title :"Group Session",
	 	details: "A group session that can recur. Clients book any session they want to join.",
	 	icon: "",
    value : "CLASS"
	},
	{
	 	title :"Event",
	 	details: "Planned ocurrence for interaction, celebration, learning, or engagement.",
	 	icon: "",
    value : "EVENT"
	},
	{
	 	title :"Workshop",
	 	details: "Interactive session for hands-on skill and knowledge acquisition.",
	 	icon: "",
    value : "WORKSHOP"
	}
]

const GuidanceArray = [
	{
	 	title :"Privacy Policy",
	 	details: "Community guidelines and code of conduct",
	 	icon: "",
    	path: "https://firebasestorage.googleapis.com/v0/b/themindedclub-prod.appspot.com/o/minded-files%2FMINDED%20-%20Privacy%20Policy%20SERVICES%20PROVIDERS%20%26%20USERS%202025%20-%20ENG.pdf?alt=media&token=e0eb9bda-0427-4a74-bde1-83e876312979"
	},
	{
	 	title :"Terms & Conditions",
	 	details: "Rules and regulations regarding application and usage",
	 	icon: "",
    	path: "https://firebasestorage.googleapis.com/v0/b/themindedclub-prod.appspot.com/o/minded-files%2FMINDED%20-%20General%20Terms%20of%20Use_ENG%202025.pdf?alt=media&token=41bf9976-b975-4be6-a855-8fa38b72ecaa"
	},
	{
	 	title :"FAQ",
	 	details: "How to dos, and other questions",
	 	icon: ""
	},
	{
	 	title :"Email us",
	 	details: "Community guideline and code of conduct",
	 	icon: "",
    	href:"mailto:hello@theminded.club"
	}
]

export {

  GuidanceArray,
	ServiceTypeArray
};