import React from "react";
import "./growth.css";
const Growth = ({onClickHandler}) => {
  return (
    <div className="growth-container">
      <div className="growth-image"></div>
      <div className="growth-description">
        <span className="growth-title">Become a Minder.</span>
        <span className="growth-title-about">
          It's all about you. Your growth, your journey, your potential.
        </span>
        <span className="growth-title-description">
          At The Minded Club your aspirations meet actionable insights, skills,
          and connections. This is where personal development transcends the
          ordinary, offering a space to connect, learn, and thrive among curious
          minds eager for knowledge. Embrace the journey as you unlock new
          dimensions of yourself and step into your true potential.
        </span>
        <span className="growth-title-mini-title">
          Become a <span  className="growth-title-mini-title" style={{
            textTransform:'uppercase'
          }}>Minder</span> now and get free exclusive access. 
        </span>

        <button onClick={onClickHandler} className="button-slider-item-growth">
          {"Start Now"}
        </button>
      </div>
    </div>
  );
};

export default Growth;
