import React, {useState, useEffect} from 'react';

import './switch.css';

const SwitchView = (props) => {
	const {label1 = "WEEK VIEW", label2 = "DAY VIEW", onHandleSwitch, fieldValue = "false", name = ""} = props;
	
	const id1 = `${name}-${label1}`;
	const id2 = `${name}-${label2}`;

	return (
		<div className="btn-group" role="group" aria-label="Basic radio toggle button group">
			<input 
				type="radio" 
				className="btn-check" 
				name={name} 
				id={id1} 
				value="false" 
				onChange={e => onHandleSwitch(e)} 
				checked={fieldValue == "false"}
			/>
			<label className="btn btn-outline-primary LINK" for={id1}>{label1}</label>

			<input 
				type="radio" 
				className="btn-check" 
				name={name}
				id={id2}
				value="true" 
				onChange={e => onHandleSwitch(e)} 
				checked={fieldValue == "true"}
			/>
			<label className="btn btn-outline-primary LINK" for={id2}>{label2}</label>
		</div>
	)
}

export default SwitchView;