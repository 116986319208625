import { useState } from "react";
import "./faq.css";
import FAQ_ICON from "../../assets/plus-sign-faq.svg";
import FAQ_ICON_PLUS from "../../assets/faq-plus-svg.svg";
import { useMediaQuery } from "@uidotdev/usehooks";
import React from "react";

const accordionData = [
  { title: "How do I join The Minded Club?", 
    content: "Joining The Minded Club is straightforward! To become one of us, a “minder”, just click on the “Start Your Journey for Free” button and complete the simple sign-up process to access a world of personal growth opportunities." 
  },
  {
    title:
      "What kind of personal growth opportunities are available to me as a minder?",
    content: "As a minder, you'll have access to a wide array of personal growth opportunities, including expert-led classes, interactive workshops, and vibrant community events covering topics such as mindfulness, wellness, career development, and relationship building.",
  },
  {
    title: "Can I customize my learning journey as a minder?",
    content: "Absolutely! The Minded Club encourages minders to tailor their learning journey according to their personal goals and interests. You can choose from various classes, workshops, and events to align with your personal growth path.",
  },
  {
    title: "How does The Minded Club enhance my personal growth journey?",
    content: "The Minded Club enhances your journey by providing a supportive community, expert guidance, and a variety of learning formats to ensure you have all you need to thrive and achieve your personal development goals.",
  },
  {
    title: "How do I keep track of my progress and activities within The Minded Club?",
    content: "The Minded Club offers a user-friendly interface that allows you to easily manage your activities, track your progress, and engage with the community, ensuring a seamless and enriching personal growth experience.",
  },
  {
    title: "Is my personal information safe with The Minded Club?",
    content: "Absolutely! The Minded Club prioritizes your privacy and security. Your personal information is protected with the utmost care and will never be shared without your explicit consent.",
  },
];

const FrequentlyAskedQuestions = ({onClickHandler}) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const isSmallDevice = useMediaQuery("only screen and (max-width : 1000px)");

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="faq-container-parent">
      <div className="faq-background-image" />
      <div className="faq-container">
        <span className="faq-title">Frequently Asked Questions</span>
      </div>

      <div className="accordion-faq">
        {accordionData.map((item, index) => (
          <div key={index} className="accordion-item">
            <div className="accordion-title" onClick={() => handleClick(index)}>
              <h3 className="accordion-item-title">{item.title}</h3>
              <div>
                <img
                  width={40}
                  src={isSmallDevice ? FAQ_ICON_PLUS : FAQ_ICON}
                />
              </div>
            </div>
            <div
              className={`accordion-content ${
                activeIndex === index ? "open" : ""
              }`}
            >
              <p>{item.content}</p>
            </div>
          </div>
        ))}

        <div className="cta-container">
          <button onClick={onClickHandler} className="button-slider-item-growth">
            {"Start Now for Free"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FrequentlyAskedQuestions;
