import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import {getAuthenticateHeader, getCookie} from '../../../../../utils/util';
import CustomizedSnackbars from  '../../../../../components/CustomizedSnackbars';

import BasicSwitch from '../../../../../components/basicSwitch/BasicSwitch';
import DateRangePickerView from '../../../../../components/DateRangePickerView';
import FloatSelectView from '../../../../../components/floatSelectView/FloatSelectView';
import Input from '../../../../../components/input/Input';
import Textarea from '../../../../../components/textarea/Textarea';

import Loading from '../../../../../components/loading/Loading';
import {baseURL} from '../../../../../actions/Constants';
import brown_cancel from '../../../../../assets/brown_cancel.png';

import {
	InitialPlanDetails,
	InitialPlanErrors,
	Price_List_Array,
	Payment_Duration_Array,
	ticketQuantityType_array, 
	Month_Array
} from '../../../../../utils/DefaultInitialValues';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const UpdatePlan = (props) => {
	const {
	    details = InitialPlanDetails,
	    isOpen = false, 
	    handleClose,
	    onUpdateSuccess
  	} = props;

  	const {
        details: loggedUser
    } = useSelector(({ loggedAdmin: { details} }) => ({ details }));
     

  	const [planDetails, setPlanDetails] = useState(details);
  	const [planError, setPlanError] = useState(InitialPlanErrors);
  	const [isLoading, setLoading] = useState(false);
  	const [isError, setError] = useState(false);

  	const isEdit = (details.id && details.id !== "");

  	const [open, setOpen] = useState(false);

  	useEffect(() => {
  		setOpen(isOpen);
  	}, [isOpen]);

  	const onUpdatePlanDetails = (e) => {
  		const fieldName = e.target.name;
  		setPlanDetails({...planDetails, [e.target.name] : e.target.value});
  	}

  	const onUpdateSwitchPlanDetails = (e, value = "") => {
  		setPlanDetails({...planDetails, [e.target.name] : value});
  	}

  	const validate = () => {
  		let isFormValid = true;

  		let planNameInvalid = false;
  		let planSessionPerMonthInvalid = false;
  		let planPriceInvalid = false;

  		let planPaymentPeriodInvalid = false;
  		let planValidityDurationInvalid = false;
  		let planTrialDurationInvalid = false;

  		if(planName === "") {
  			planNameInvalid = true;
  			isFormValid = false;
  		}

  		if(planSessionType === "limited") {
  			if(planSessionPerMonth === "") {
  				planSessionPerMonthInvalid = true;
  				isFormValid = false;
  			}
  		}

  		if(planPurchaseMethod === "recurring") {
  			if(planPrice === "") {
  				planPriceInvalid = true;
  				isFormValid = false;
  			}
  			if(planPaymentPeriod === "") {
  				planPaymentPeriodInvalid = true;
  				isFormValid = false;
  			}
  			if(planValidityDuration === "") {
  				planValidityDurationInvalid = true;
  				isFormValid = false;
  			}

  			/*if(planFreeTrialEnabled) {
  				if(planTrialDuration === "") {
  					planTrialDurationInvalid = true;
  					isFormValid = false;
  				}
  			}*/
  		} else if(planPurchaseMethod === "onetime") {
  			if(planPrice === "") {
  				planPriceInvalid = true;
  				isFormValid = false;
  			}
  			if(planValidityDuration === "") {
  				planValidityDurationInvalid = true;
  				isFormValid = false;
  			}
  		} else {
  			if(planValidityDuration === "") {
  				planValidityDurationInvalid = true;
  				isFormValid = false;
  			}
  		}

  		setPlanError({...planError,
			plan_name_error : planNameInvalid,
			plan_session_per_month_error : planSessionPerMonthInvalid,
			plan_price_error : planPriceInvalid,
			plan_payment_period_error : planPaymentPeriodInvalid,
			plan_validity_duration_error : planValidityDurationInvalid,
			plan_trial_duration_error : planTrialDurationInvalid
  		});

  		return isFormValid;
  	}

  	const onSubmit = () => {
  		const isValid = validate();
  		if(isValid) {
  			updatePlanDetails();
  		}
  	}

  	const updatePlanDetails = async () => {
        const URL= isEdit ? `${baseURL}/minded-services/updatePlanById/${planDetails.id}` : `${baseURL}/minded-services/addPlan`;
        setLoading(true);

        planDetails.createdBy = loggedUser?.userId;

        if(planSessionType === "unlimited") {
        	planDetails.planSessionPerMonth = -1;
        }

        if(planPurchaseMethod === "onetime") {
        	planDetails.planPaymentPeriod = "";
        	planDetails.planTrialDuration = 0;
        	planDetails.planFreeTrialEnabled = false;
        }

        if(planPurchaseMethod === "free") {
        	planDetails.planPaymentPeriod = "";
        	planDetails.planTrialDuration = 0;
        	planDetails.planFreeTrialEnabled = false;
        }

		//const myHeaders = getAuthenticateHeader();
        return await fetch(URL, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            body: JSON.stringify(planDetails), // body data type must match "Content-Type" header
		    headers: {
		        'Content-Type': 'application/json'
		    }
        })
        .then(response => {
		    const resp = response;

		    const {status = ""} = resp;
		    setLoading(false);

		    if(status === 200 || status === 201) {
		      return response.json().then(res => { 
		       	setError(false);
	            onUpdateSuccess();
	            handleClose();
		      });
		      
		    } else {
		      //Error
		       setError(true); 
		    }
		  })
		.catch((error) => {
			console.log('error updatePlanDetails/addPlanDetails', error);
            setLoading(false);
            setError(true); 
		});
    }

  	const {
		id = "",
		planName = "",
		planDescription = "",
		planSessionType = "limited",
		planSessionPerMonth = "",
		planPurchaseMethod = "recurring",
		planPrice = "",
		priceCurrency = "EUR",
		planPaymentPeriod = "month",
		planValidityDuration = "",
		planFreeTrialEnabled = "true",
		planTrialDuration = "",  //in months
		createdBy = ""
  	} = planDetails || {};

  	const {
  		plan_id_error = false,
		plan_name_error = false,
		plan_session_type_error = false,
		plan_session_per_month_error = false,
		plan_price_type_error = false,
		plan_price_error = false,
		plan_payment_period_error = false,
		plan_validity_duration_error = false,
		plan_free_trial_enabled_error = false,
		plan_trial_duration_error = false
  	} = planError || {};


  	const title = isEdit ? "Update Plan" : "Create New Plan";

	return (
		<>
	    <BootstrapDialog
	        onClose={handleClose}
	        aria-labelledby="customized-dialog-title"
	        open={open}
	    >

	        <DialogActions className="w-100 p-4 bg-white">
		        <div className="w-100 d-flex justify-content-between align-items-center">
		          	<div>
		   
		          	</div>

		          	<h6 className="heading-6 color-primary-50">
		            	{title}
		          	</h6>

		          	<div>
		            	<button     
		              		onClick={handleClose}
		              		aria-label="close"
		              		className="close_icon ax-button"
		            	>
		              		<img src={brown_cancel} className="close_icon" />
		            	</button>
		          	</div>
			    </div>
	    	</DialogActions>

	        <DialogContent className="bg-white mb-0">
	      		<div className="container-fluid">
	      			<div className="row">

	      				<div className="col-lg-12">
	      					<div className="w-100 my-3">
					            <div className="w-100">
					              <Input
					                name="planName"
					                label="Plan name"
					                placeHolder="xxx" 
					                value={planName}
					                onInputChange = {e => onUpdatePlanDetails(e)}
					              />
					            </div>
					            {plan_name_error && <div className="text-danger">! Plan name is a required field</div>}
					        </div>
					    </div>
					    <div className="col-lg-12 my-3">
					   		<Textarea
					   			label="Description (optional)"
					   			id="planDescription" 
					   			name="planDescription" 
					   			value={planDescription} 
					   			className="w-100" 
					   			
					   			placeHolder="max 300 charecters"
					   			onInputChange={(e) => onUpdatePlanDetails(e)} 
					   		/>
              			</div>

              			<div className="col-lg-12 my-3">
              				<h5 className="heading-5 color-primary-50">Sessions</h5>
		      				<div className="d-flex align-items-center">
		      				{ticketQuantityType_array.map((item, index) => {
		      					return (
		      						<div className="pe-3">
		      							<input 
		      								id={item.value} 
		      								value={item.value} 
		      								type="radio" 
		      								name="planSessionType"
		      								checked = {planSessionType === item?.value} 
		      								onChange = {e => onUpdatePlanDetails(e)}
		      							/>
		      							<label for={item.value} className="px-2 body-R color-primary-50">{item?.label}</label>
		      						</div>
		      					)
		      				})}
		      				</div>
		      			</div>

		      			{planSessionType === "limited" && <div className="col-lg-12 my-3">
				            <div className="w-100">
				              <Input
				                name="planSessionPerMonth"
				                label="No. of sessions per month"
				                placeHolder="xxx" 
				                value={planSessionPerMonth}
				                type="number"
				                onInputChange = {e => onUpdatePlanDetails(e)}
				              />
				              {plan_session_per_month_error && <div className="text-danger">! Plan Session is a required field</div>}
				              
				            </div>
					    </div>}

					    {planSessionType !== "limited" && <div className="col-lg-12 my-3">
				            <div className="w-100 color-primary-50 caption-R">
				              No limitaion on session
				            </div>
					    </div>}

					    <div className="col-lg-12 my-3">
              				<h5 className="heading-5 color-primary-50 my-3">Price & Durations</h5>
		      				<div className="responsive_flex">
		      				{Price_List_Array.map((item, index) => {
		      					return (
		      						<div className="pe-3">
		      							<input 
		      								id={item.value} 
		      								value={item.value} 
		      								type="radio" 
		      								name="planPurchaseMethod"
		      								checked = {planPurchaseMethod === item.value}  
		      								onChange = {e => onUpdatePlanDetails(e)} 
		      							/>
		      							<label for={item.value} className="px-2 body-R color-primary-50">{item.label}</label>
		      						</div>
		      					)
		      				})}
		      				</div>
		      			</div>

		      			{planPurchaseMethod !== "free" && <div className="col-lg-12">
	      					<div className="w-100 my-3">
				              	<Input
					                name="planPrice"
					                label="Price (EUR)"
					                placeHolder="xxx" 
					                value={planPrice}
					                type="number"
					                onInputChange = {e => onUpdatePlanDetails(e)}
				              	/>
				              	{plan_price_error && <div className="text-danger">! Plan price is a required field</div>}
					        </div>
					    </div>}

					    {((planPurchaseMethod === "onetime") || (planPurchaseMethod === "free")) && <div className="col-lg-12">
					    	<div className="w-100 my-3">
						    	<FloatSelectView
					                name = "planValidityDuration"
					                label = "Membership is valid for"
					                list = {Month_Array}
						            extraText = "Month"
					                value = {planValidityDuration}
					                onInputChange = {(e) => onUpdatePlanDetails(e)} 
	              				/>
	              				{plan_validity_duration_error && <div className="text-danger">! Plan Validity is a required field</div>}
		              		</div>
					    </div>}

					    {planPurchaseMethod === "recurring" &&
					    <>
						    <div className="col-lg-12">
		      					<div className="w-100 my-3">
							    	<FloatSelectView
						                name = "planPaymentPeriod"
						                label = "Payment period"
						                list = {Payment_Duration_Array}
						                extraText = ""
						                value = {planPaymentPeriod}
						                onInputChange = {e => onUpdatePlanDetails(e)} 
		              				/>
		              				{plan_payment_period_error && <div className="text-danger">! Plan payment period is a required field</div>}
		              			</div>
		              		</div>

		              		<div className="col-lg-12">
		      					<div className="w-100 my-3">
							    	<FloatSelectView
						                name = "planValidityDuration"
						                label = "Payment stop after"
						                list = {Month_Array}
						                extraText = "Month"
						                value = {planValidityDuration}
						                onInputChange = {e => onUpdatePlanDetails(e)} 
		              				/>
		              				{plan_validity_duration_error && <div className="text-danger">! Plan validity period is a required field</div>}
		              			</div>
		              		</div>
	              		</>
	              		}
	      			</div>
				</div>

	        </DialogContent>

	        <DialogActions className="bg-white">
	        	<div className="py-3">
		        	<button autoFocus onClick={handleClose} className="tertiary_btn capitalize color-primary-50 mx-2 px-2">
		            	Cancel
		          	</button>

		          	<button autoFocus onClick={onSubmit} className="secondary_btn capitalize">
		           		{isEdit ? "Update" : "Add"}
		          	</button>
	            </div>
	        </DialogActions>
      	</BootstrapDialog>
      	{isLoading && <Loading />}
      	{isError && <CustomizedSnackbars isShow={isError} text="Something went wrong. Please try after sometime." showAlert={(isHide) => setError(false)} />}
    </>
	)
}

export default UpdatePlan;
