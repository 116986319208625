import {getDateFromEpoc, getTimeFromEpoc} from '../../../../utils/util';
import {getTimeZoneKey} from '../../../../utils/DefaultStates';

const TicketBookingListView = (props) => {

	const {onHandleScheduleNow,  list = []} = props;
	//const [isExpanded, setExpanded] = useState(false);

	const date_wise_array = list;

	return (
		<>
			{date_wise_array?.length === 0 && <div className="h-75 my-3  py-lg-3 py-2 px-3 color-primary-50 body-R text-center d-flex align-items-center justify-content-center">
				No Tickets
			</div>}

			{date_wise_array.length !== 0 && date_wise_array.map((eachBooking, indexVal) => {

			const {
				scheduleDate = 0,
				serviceDetails = {},
				scheduleStartTime = 0,
				scheduleEndTime = 0
			} = eachBooking?.schedule || {};

			const {serviceTimezone = "", serviceName = "", userDetails = {}, startTime = "", endTime = "", serviceType = ""} = serviceDetails || {};
			
			return (
				<>
					<div className="desktop_view1">
						<div className="d-flex align-items-center justify-content-between">
							<div>
								<div className="caption-R color-primary-50 py-1">{userDetails?.userName}</div>
								
								<div className="subtitle-B color-primary-50 text-left" >
									{serviceName}
								</div>
								{serviceType !== "PRIVATE" && <div className="caption-R color-primary-50 py-1">{getDateFromEpoc(scheduleDate)},  {startTime} - {endTime} {getTimeZoneKey(serviceTimezone)}</div>}
								{serviceType === "PRIVATE" && <div className="caption-R color-primary-50 py-1">{getDateFromEpoc(scheduleDate)},  {getTimeFromEpoc(scheduleStartTime, serviceTimezone)} - {getTimeFromEpoc(scheduleEndTime, serviceTimezone)} {getTimeZoneKey(serviceTimezone)}</div>}
							</div>

							<button className="capitalize tertiary_btn color-primary-50" onClick={() => onHandleScheduleNow(eachBooking)}>
								Contact Us
							</button>
						</div>
					</div>

					{(indexVal !== (date_wise_array.length - 1)) && 
						<div className="">
							<hr />
						</div>
					}
				</>
			)
		})}
		</>
	)
}

export default TicketBookingListView;