import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import { Breadcrumb } from "rsuite";

import CustomizedSnackbars from  '../../../../components/CustomizedSnackbars';
import ClassListView from '../../../../components/classListView/ClassListView';
import FilterView from '../../../../components/filters/FilterView';
import FilterView2 from '../../../../components/filters/FilterView2';

import TicketBookingListView from './TicketBookingListView';
import PackageBookingListView from './PackageBookingListView';

import HelmetDetails from "../../../../components/HelmetDetails";
import Loading from '../../../../components/loading/Loading';

import Noresult from '../../../../components/no-result/Noresult';
import ScheduleDialog from '../../../events-classes/scheduleDialog/ScheduleDialog';
import OnBoardingDialog from '../../../../pages/admin/onBoardingAdmin/OnBoardingDialog';
import SelectwithoutLabel from '../../../../components/selectwithoutLabel/SelectWithoutLabel';

import TabView from '../../../../components/tabView/TabView';

import {Ticket_Tab_List, headerOption} from '../../../../utils/DefaultInitialValues';
import {mindersBasicPlanTitle} from '../../../../actions/Constants';
import {generateEventsDropdownList, getTicketTypeFromActiveTab, getCurrentMonthFirstDayEpoc} from '../../../../utils/util';
import {getBookings} from '../../../../actions/bookings.actions';
import {getSchedules} from '../../../../actions/schedules.actions';
import {baseURL, mindedClubUserId} from '../../../../actions/Constants';

import avatar from '../../../../assets/avatar.png';
import right_arrow from '../../../../assets/thin arrow - right.png';
import '../dashboard.css';
import React from 'react';
import { getServices } from '../../../../actions/services.actions';

const CustomerDashboard = () => {
	const dispatch = useDispatch();
	const getServicesAction = getServices;
	const getSchedulesAction = getSchedules;

	const location = useLocation();
	const navigate = useNavigate();
	const [crumb, setCrumb] = useState([]);

	const getBookingsAction = getBookings;
  	const [user, setUser] = useState({});
	
	const [activeTab, setActiveTab] = useState(0);
	const [service_Type, setServiceType] = useState("ticket");
	const [serviceStatus, setServiceStatus] = useState("booked");
	const [customerDashboardDetails, setCustomerDashboardDetails] = useState({});
	const [learntedFromCatalyst, setLearntFromCatalyst] = useState("-");

	const [allServices, setAllServices] = useState([]);
	const [allBookings, setAllBookings] = useState([]);

	const [allBookingList, setAllBookingList] = useState([]);
	const [filerBookingList, setFilterBookingList] = useState([]);
	
	const [allDropdownList, setAllDropdownList] = useState({});
	const [allDropdownList2, setAllDropdownList2] = useState({});
	const [selectedBooking, setSelectedBooking] = useState({});

	const [allSchedules, setAllSchedules] = useState([]);
	const [filterSchedules, setFilterSchedules] = useState([]);
	const [filterSchedulesMindedClub, setFilterSchedulesMindedClub] = useState([]);

	const [selectedFilters, setSelectedFilters] = useState({
		selectedCatalyst : [],
		selectedLocation: [],
		selectedCategory : []
	});

    const [isShowSchedule, setShowSchedule] = useState(false);
    const [purchaseId, setPurchaseId] = useState("");
    const [purchaseDetails, setPurchaseDetails] = useState({});

	const [isShowDetails, setShowDetails] =  useState(false);
	const [navigateStepper, setNavigateStepper] = useState(0);

    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    
    const {
        details: loggedUser,
        eeror: error,
        loading: loading
    } = useSelector(({ loggedAdmin: { details} }) => ({ details }));

    const {
       details: bookingsDetails,
       loading: bookingsLoading,
       error: bookingsError
    } = useSelector(({ bookings: { details, loading, error} }) => ({ details, loading, error }));

	const {
		details: servicesDetails,
		loading: servicesLoading,
		error: servicesError
	 } = useSelector(({ services: { details, loading, error} }) => ({ details, loading, error }));

	 const {
		details: schedulesDetails,
		loading: schedulesLoading,
		error: schedulesError
	 } = useSelector(({ schedules: { details, loading, error} }) => ({ details, loading, error }));

    
    useEffect(() => {
    	onStartView();
    	getCustomerDashboardDetails();
    	getLearntFromCataLystCount();
    }, []);

    const onStartView = () => {
    	window.scrollTo({top: 0, behavior: "smooth"});
    	getCustomerById();
    	dispatch(getBookingsAction("", "", "", loggedUser?.userId, "", "", "", "", service_Type));    
		// dispatch(getServicesAction("678a6f98bcd9ab341e877479", "", "", "", "", "", "active"));
	}

    useEffect(() => {
    	if(bookingsDetails && Array.isArray(bookingsDetails)) {
    		const filterSuccessBookings = bookingsDetails.filter(item => (item?.bookingStatus === "active" || item?.bookingStatus === "expired"));
    		setAllBookings(filterSuccessBookings);
    	}
    }, [bookingsDetails]);

    useEffect(() => {
    	if(allBookings && Array.isArray(allBookings)) {
    		const extractedBookings = allBookings.map((item) => {
	  			return item?.schedule;
	  		});
	  		setFilterSchedules(extractedBookings);
	  		setAllBookingList(extractedBookings);
    	}
    }, [allBookings]);

    useEffect(() => {
    	if(Array.isArray(allBookingList)) {
    		const obj = generateEventsDropdownList(allBookingList);
    		setAllDropdownList(obj);
    		applyFilter();
    	}
    }, [allBookingList]);

	
	useEffect(() => {
    	if(Array.isArray(schedulesDetails)) {
    		const filterSuccessMindedClubSChedules = schedulesDetails.filter(item => ((item?.scheduleStatus === "active" || item?.scheduleStatus === "expired") && item?.userId === mindedClubUserId));
			
			setFilterSchedulesMindedClub(filterSuccessMindedClubSChedules);
			const obj = generateEventsDropdownList(filterSuccessMindedClubSChedules);
    		setAllDropdownList2(obj);
    	}
    }, [schedulesDetails]);
	


    const getCustomerById = async () => {
		setLoading(true);
		const URL = `${baseURL}/minded-user/getUserById/${loggedUser?.userId}`;

       	return await fetch(URL, headerOption)
      	.then(response => {
		    const resp = response;
		    const {status = ""} = resp;
		    setLoading(false);

		    if(status === 200 || status === 201) {
		      return response.json().then(res => {
		        setUser(res);
		      });
		    } else {
		      	console.log('error getCustomerById', error);
	      		//setLoading(false);
	      		setError(true);
		    }
		  })
		  .catch((error) => {
		  	console.log('error getCustomerById', error);
	      	setLoading(false);
	      	setError(true);
		});
	}

	const getLearntFromCataLystCount = async () => {
		setLoading(true);
		const currentMonthFirstDayEpoc = getCurrentMonthFirstDayEpoc();
		const currentEpoc = new Date().getTime();
		const URL = `${baseURL}/minded-services/learnedFromCatalystCount?userId=${loggedUser?.userId}&startDate=${currentMonthFirstDayEpoc}&endDate=${currentEpoc}`;

       	return await fetch(URL, headerOption)
      	.then(response => {
		    const resp = response;
		    const {status = ""} = resp;
		    setLoading(false);

		    if(status === 200 || status === 201) {
		      return response.json().then(res => {
		        setLearntFromCatalyst(res?.count);
		      });
		    } else {
		      	console.log('error getLearntFromCataLystCount', error);
	      		//setLoading(false);
	      		setError(true);
		    }
		  })
		  .catch((error) => {
		  	console.log('error getLearntFromCataLystCount', error);
	      	setLoading(false);
	      	setError(true);
		});
	}

  	const onHandleScheduleNow = (purchaseInfo = {}) => {
  		setPurchaseDetails(purchaseInfo);
		setPurchaseId(purchaseInfo?.purchaseId);
	}

	const onScheduleDialogClose = () => {
		setShowSchedule(false);
		setPurchaseDetails({});
		setPurchaseId("");
		onStartView();
	}

	useEffect(() => {
		if(purchaseId !== "" && !isShowSchedule) {
			setShowSchedule(true);
		}
	}, [purchaseId]);

    const applyFilter = () => {
  
    	const { selectedCatalyst = [], selectedLocation = [], selectedCategory = [] } = selectedFilters || {};

    	let filterArray = allBookingList;

    	if(selectedCatalyst.length) {
    		filterArray = allSchedules.filter((item, index) => selectedCatalyst.includes(item?.serviceDetails?.userDetails?.userId));
    	}

    	if(selectedCategory.length) {
    		filterArray = filterArray.filter((item, index) => selectedCategory.includes(item?.serviceDetails?.serviceCategoryDetails?.serviceCategoryId));
    	}

    	if(selectedLocation.length) {
    		filterArray = filterArray.filter((item, index) => selectedLocation.includes(item?.serviceDetails?.serviceLocation?.city));
    	}

    	setFilterBookingList(filterArray);
    }

    const onCheckboxSelect = (name, val) => {
    	setSelectedFilters({...selectedFilters, [name] : val});
    }

    useEffect(() => {
    	let type = "ticket";
    	let filterBooking = [];

    	switch(activeTab) {
    		case 0:
    			type = "ticket";
    			break;
    		case 1:
    			type = "package";
    			break;
    		case 2:
    			type = "plan";
    			break;
    		default: break;
    	}

    	const filterVal = allBookings.filter(item => item.type === type);
    	//setFilterBookings(filterVal);
    }, [allBookings, activeTab]);

    useEffect(() => {
    	applyFilter(allBookings);
    }, [selectedFilters]);

   	const getCustomerDashboardDetails = async () => {
   		setLoading(true);
   		const currentMonthFirstDayEpoc = getCurrentMonthFirstDayEpoc();
		const currentEpoc = new Date().getTime();
		const URL = `${baseURL}/minded-services/getCustomerDashboardDetails/?uid=${loggedUser?.userId}&startDate=${currentMonthFirstDayEpoc}&endDate=${currentEpoc}`;

       	return await fetch(URL, headerOption)
      	.then(response => {
		    const resp = response;
		    const {status = ""} = resp;
		    setLoading(false);

		    if(status === 200 || status === 201) {
		      return response.json().then(res => {

		      	const obj = {};
		      	obj.attendedClassCount = (res && res[0]?.attendCount) || 0;
		      	obj.attendedEventCount = (res && res[1]?.attendCount) || 0;
		      	obj.attendedPrivateCount = (res && res[2]?.attendCount) || 0;

		        setCustomerDashboardDetails(obj);
		      });
		    } else {
		      	console.log('error getCustomerDashboardDetails', error);
	      		setError(true);
		    }
		  })
		  .catch((error) => {
		  	console.log('error getCustomerDashboardDetails', error);
	      	setLoading(false);
	      	setError(true);
		});
   	}

    useEffect(() => {
    	const serviceType = getTicketTypeFromActiveTab(activeTab);
    	setServiceType(serviceType);
    	dispatch(getBookingsAction("", "", "", loggedUser?.userId, "", "", "", "", serviceType));
    }, [activeTab]);

    useEffect(() => {
        let locationVal = location?.pathname;
        const crumbs = locationVal.split("/");
        createCrumbDetails(crumbs);
    }, [location]);

    const onHandleBuyNowPlanClick = (serviceId = "") => {
    	navigate(`/events-classes/service-details/${serviceId}`);
    }

    const handleDateChange = (stDate, edDate) => {
		console.log('handleDateChange 1')
    }
	const handleDateChange2 = (stDate, edDate) => {
		console.log('handleDateChange 2')

		// const type = getCatalystDetailsServiceTypeFromActiveTab(activeServiceTab);
		const type = "";

    	if(stDate && edDate) {
	    	const startDateEpoc = new Date(stDate).getTime();
	    	const endDateEpoc   = new Date(edDate).getTime();
    	}
    }

	const getCatalystDetailsServiceTypeFromActiveTab = (activeTab = 0) => {
		let type = "";
	
		if(activeTab === 0) {
			type = "";
		}
		else if(activeTab === 1) {
			type = "CLASS";
		} else if(activeTab === 2) {
			type = "PRIVATE";
		} else {
			type = "EVENT";
		}
	
		return type;
	}

    const onRescheduleBtnClick = () => {
    	navigate("/contact");
    }
	const onRescheduleBtnClick2 = (item) => {
		console.log('onRescheduleBtnClick', item);
		console.log('mindersBasicPlanTitle', mindersBasicPlanTitle);
		

		if (user.status !== "active"  || userHasBasicPlan) {
			setNavigateStepper(2);
			setShowDetails(true);
		} else {
			console.log( user.catalystDetails.planDetails.subscriptionName);
			window.open(item?.serviceDetails?.serviceScheduleDetails?.serviceMeetingLink, "_blank");
		}	
    }

    const TicketTabpanel = (service_Type = "") => {
    	const ticketList = allBookings.filter((eachItem, idx) => eachItem?.bookingStatus === 'active');
    	return (
    		<div className="list_view">
				{ticketList.length === 0 && <div> <Noresult serviceName = "Ticket" onCTAClick = {() => console.log("Ticket")} /> </div>}
				{ticketList.length > 0 && <TicketBookingListView list = {ticketList} onHandleScheduleNow = {(val) => onRescheduleBtnClick(val)} />}
			</div>
    	)
    }

    const PackageTabpanel = (service_Type = "") => {
    	const ticketList = allBookings.filter((eachItem, idx) => eachItem?.bookingStatus === 'active');
    	return (
    		<div className="list_view">
				{ticketList.length === 0 && <div> <Noresult serviceName = "Package" onCTAClick = {() => console.log("Package")} /> </div>}
				{ticketList.length > 0 && <PackageBookingListView list = {ticketList} onHandleScheduleNow ={(val) => onHandleScheduleNow(val)} />}
			</div>
    	)
    }

    const PlanTabpanel = (service_Type = "") => {
    	const ticketList = allBookings.filter((eachItem, idx) => eachItem?.bookingStatus === 'active');
    	return (
    		<div className="list_view">
				{ticketList.length === 0 && <div> <Noresult serviceName = "Plan" onCTAClick = {() => console.log("Plan")} /> </div>}
				{ticketList.length > 0 && <PackageBookingListView list = {ticketList} onHandleScheduleNow = {(val) => onHandleScheduleNow(val)} onHandleBuyNowClick = {(sId) => onHandleBuyNowPlanClick(sId)} />}
			</div>
    	)
    }

    const TabPanel = (service_Type = "") => {
		return (
			<>
				{activeTab === 0 && <TicketTabpanel service_Type = {service_Type} />}
				{activeTab === 1 && <PackageTabpanel service_Type = {service_Type} />}
				{activeTab === 2 && <PlanTabpanel service_Type = {service_Type} />}
			</>
		);
	}

	const createCrumbDetails = (crumbs) => {
		const crumbArray = [];
		
		crumbs.map((crmb, index) => {
			const obj = {};

			switch(crmb) {
				case "":
					obj.path = "/";
					obj.label = "Home";
					crumbArray.push(obj);
					break;
				case "dashboard":
					obj.path = "/dashboard";
					obj.label = "My Dashboard";
					crumbArray.push(obj);
					break;
				case "settings":
					obj.path = "/dashboard/settings";
					obj.label = "Settings";
					crumbArray.push(obj);
					break;
				default: break;
				
			}

			setCrumb(crumbArray);
		});
	}

	const {
		
		email = "",
		name = "",
		phone = "",
		profileDescription = {}
	} = user || {};

	const {
		profilePic = ""
	} = profileDescription || {};

	const isLoadingState = bookingsLoading || isLoading || loading;
	const {attendedClassCount = "--", attendedEventCount = "--", attendedPrivateCount = "--"} = customerDashboardDetails || {};
	const userAtualPlan = user?.catalystDetails?.planDetails?.subscriptionName;
	const userStatusActive = user?.status === "active" 
	const userHasBasicPlan = userStatusActive && userAtualPlan === mindersBasicPlanTitle;

	//console.log("filters Schedules", filterSchedules);

	const filterDetails = useSelector(({ filter: { details } }) => details);

	const filterDetails2 = useSelector(({ filter: { details2 } }) => details2);

	return (
		<div className="main-no-content-padding">
			<HelmetDetails title="Customer Dashboard | Minded." />
			<Breadcrumb> 
                {crumb.map((item, index) => {
                	return (index !== crumb.length - 1) ? (
	                	<>
		                	<Link to={item.path}>
		                		<span className={`body-R color-secondary-100 px-2`}>{item.label}</span>
		                	</Link>
		                	<img src={right_arrow} style={{width: '12px', height: '12px'}} alt="" />
	                	</>
	                	) : (<span className={`body-R color-neutral-50 px-2`}>{item?.label}</span>)
                	}
                )}
	        </Breadcrumb>
			<div className="container-fluid">
				
				<div className="row">
					<div className="col-lg-12">
						<div className="d-flex justify-content-between">
							<div className="">
								<h1 className="heading-1 color-primary-50">My Dashboard</h1>
							</div>
						</div>
					</div>
				</div>

				<div className="row my-4">
					<div className="col-lg-3 col-md-5 my-3">
						<div className="card-details rounded-4 pb-4 bg-white">
							<img src={profilePic || avatar} className="catalyst_profile_img" alt = "" />
							<div className="p-4">
								<div className="">
									<h5 className="heading-5 color-primary-50">{name}</h5>
								</div>
								
								<div className="body-R color-primary-50">{email}</div>
								<div className="body-R color-primary-50">{phone}</div>
							</div>
							<div className="px-4 d-flex justify-content-center w-100">
								<Link 
									to="/dashboard/profile" 
									className="text-center px-3 m-auto tertiary_btn color-primary-50 capitalize" 
								>
									VIEW PROFILE SETTINGS
								</Link>
							</div>
						</div>
					</div>

					<div className="col-lg-9 col-md-7  my-3">
						<div className="w-100 report_view">
							<div className="w-100 d-flex justify-content-around align-items-center h-100">
								<div className="text-center">
									<h2 className="heading-2 color-primary-50">{attendedEventCount}</h2>
									<div className="caption-R color-primary-50">Attended Events</div>
								</div>

								<div className="text-center">
									<h2 className="heading-2 color-primary-50">{attendedClassCount}</h2>
									<div className="caption-R color-primary-50">Attended Classes</div>
								</div>

								<div className="text-center">
									<h2 className="heading-2 color-primary-50">{attendedPrivateCount}</h2>
									<div className="caption-R color-primary-50">Attended Private</div>
								</div>

								<div className="text-center">
									<h2 className="heading-2 color-primary-50">{learntedFromCatalyst}</h2>
									<div className="caption-R color-primary-50">Learned from catalysts</div>
								</div>
							</div>
						</div>
						
						<div className="card-details rounded-4 bg-white p-lg-5 p-3">
							<div className="d-flex justify-content-between align-items-center">
								<div>
									<h5 className="heading-4 color-primary-50">My Bookings</h5>
								</div>
								<div>
									<SelectwithoutLabel
										isDisabled = {true}
										name="serviceStatus"
										value = {serviceStatus}
						                onInputChange = {e => console.log(e)}
						                list = {
						                	[{
												"label": "Booked",
										    	"value": "booked"
										  	},
										  	{
										    	"label": "Cancelled",
										    	"value": "cancelled"
											},
											{
										    	"label": "Completed",
										    	"value": "completed"
											}
											]
										} 
						            />
			              		</div>
							</div>
							<TabView service_Type = {service_Type} children = {TabPanel} tabs={Ticket_Tab_List} onTabClick = {(val) => setActiveTab(val)} />
						</div>
					</div>
				</div>
				<h4 className="heading-4 color-primary-50">Minded Club Schedule | Growthaholic Subscription</h4>
				<p className="color-primary-50">Subscribe now to enjoy free sessions to empower your personal growth.</p>
			
				{user && Object.keys(user).length > 0 && (
					<>
						<div className="container-fluid my-3">
							<FilterView2 
								key = {1}
								onHandleDateChange = {handleDateChange2} 
								allDropdownList = {allDropdownList2}
								onCheckboxSelect = {onCheckboxSelect} 
							/>
						</div>
				
						<ClassListView 
							key = {2}
							filtersParameter={filterDetails2} 
							onHandleCTAClick = {(item) => onRescheduleBtnClick2(item)}
							serviceList = {filterSchedulesMindedClub}
							CTALabel = {userHasBasicPlan ? 'SUBSCRIBE' : 'JOIN'}
							isCTAShow = {true}
							service_Type = {service_Type}
							isCTADisabled = {false}
							isShowMeetingLink = {true}
						/>
					</>
				)}

				<h4 className="heading-4 color-primary-50">My Schedule</h4>
			
				<div className="container-fluid my-3">
					<FilterView 
						key = {3}
						onHandleDateChange = {handleDateChange} 
						allDropdownList = {allDropdownList}
						onCheckboxSelect = {onCheckboxSelect} 
					/>
				</div>
		
				<ClassListView 
					key = {4}
					filtersParameter={filterDetails} 

					onHandleCTAClick = {(item) => onRescheduleBtnClick(item)}
					serviceList = {filterSchedules}
					CTALabel = "Contact US"
					isCTAShow = {true}
					service_Type = {service_Type}
					isCTADisabled = {false}
					isShowMeetingLink = {true}
				/>
				
				{isShowSchedule && <ScheduleDialog 
					isOpen = {isShowSchedule} 
					onHandleClose = {onScheduleDialogClose}
					purchaseId = {purchaseId}
					service_Type = {service_Type}

				/>
				}
			</div>
			<div className="fs-6 body-R color-primary-50">** In case for any unforeseen circumstances impact your session, select the 'Contact Us' button for assistance.</div>
			{isShowDetails && <OnBoardingDialog 
				isUpgradingPlan = {true}
				isOpen = {isShowDetails}
				userinfo = {user}
				onHandleClose={() => setShowDetails(false)} 
				navigateStepper = {navigateStepper} />
			}
			{isLoadingState && <Loading />}
			{bookingsError && <CustomizedSnackbars isShow={bookingsError} text="Something went wrong. Please try after sometime." showAlert={(isHide) => console.log("")} />}  
		</div>
	)
}

export default CustomerDashboard;