import React, {useState, useEffect} from 'react';
import {UserTypeArray} from '../../assets/data';
import Input from '../input/Input';
import MyTimer from '../timer/Mytimer';
import GoogleIcon from '../../assets/google.png';
import facebookIcon from '../../assets/facebook.png';

const OTPView = (props) => {

  const { 
    userDetails = {},
    errorMsg = "", 
    isShowOTP = false, 
    otp = "",
    isInvalidOTP = false,
    setOtp,
    resendOTP,
    onHandleChange
  } = props;
  
  const [timer, setTimer] = useState(new Date());
  const [isTimeExpire, setTimeExpire] = useState(false);

  const handleChange = (e, index) => {
    const element = e.target;
    
    if (isNaN(element.value)) return false;
    setOtp([...otp.map((d,idx) => (idx === index) ? element.value: d)]);

    if(element.value != "" && index < 5) {
      const ele = document.getElementById(`digit-${index+2}`);
      ele.focus();
    }
  }

  useEffect(() => {
    if(isShowOTP) {
      setTimer(time);
    }
  }, [isShowOTP]);

  const handleResendOTP = () => {
    setTimer(time);
    setTimeExpire(false);
    resendOTP();

  }

  const handleKeydown = (e, index) => {
    const keyCode = e.which;

    switch(keyCode) {
      case 37:
      case 38:
        if(index <= 5 && index > 0) {
          const ele = document.getElementById(`digit-${index}`);
          if(ele) ele.focus();
        }
      break;
      case 39:
      case 40:
        if(index <= 4) {
          const ele = document.getElementById(`digit-${index+2}`);
          if(ele) ele.focus();
        }
      break;
      default: break;
    }
  }

  const time = new Date();
  time.setSeconds(time.getSeconds() + 119);

  const onHandleTimeExpire = () => {
    setTimeExpire(true);
  }

  const {
    email = ""
  } = userDetails || {};

  return (
    <div className="w-100 rounded">
      <div className="login_form">
        <div className="w-100">
          {/*<button className="capitalize">sign up with google</button>
          <div className="white_text py-4">OR, Continue with email</div>*/}

          {!isShowOTP && <div className="white_text">
            <Input
              name = "email"
              label = "Email"
              type = "email"
              placeHolder = "xxx@xxx.com" 
              value = {email}
              isRequired = {true}
              onInputChange = {(e) => onHandleChange(e)} 
            />

            {!isShowOTP && <p className="color-neutral-70 caption-R py-1">We'll send you a verification code to confirm your email.</p>}
            {errorMsg && <p className="white_text pt-1">! {errorMsg}</p>}
          </div>}

          {isShowOTP && <div className="white_text">
            
            <div>
              {otp.map((val, index) => {
                return (
                  <input
                    className="otp-field"
                    id={`digit-${index+1}`}
                    type="text"
                    name={`digit-${index+1}`}
                    maxLength="1"
                    key={index}
                    value={val}
                    focus={index === 0}
                    onKeyDown = {e => handleKeydown(e, index)} 
                    onChange = {e => handleChange(e, index)}
                  />
                );
              })}
            </div>
            {isInvalidOTP && <div className="body-R text-danger py-3">! Invalid OTP</div>}
            {!isTimeExpire && <div className="body-R color-neutral-100 py-3">Didn't receive verification code? Resend in <MyTimer expiryTimestamp={time} onExpire={onHandleTimeExpire} /></div>}
            {isTimeExpire && <div className="body-R color-neutral-100 py-3">Didn't receive verification code? <button type="button" className="ax-button" onClick={handleResendOTP}>Resend OTP</button></div>}
          
          </div>}
        </div>

        <div className="w-100">
          <div className="white_text">
            <div className="body-R color-neutral-100">
              By continuing, I agree to the <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/themindedclub-prod.appspot.com/o/minded-files%2FMINDED%20-%20General%20Terms%20of%20Use_ENG%202025.pdf?alt=media&token=41bf9976-b975-4be6-a855-8fa38b72ecaa" target="_blank" className="white_text body-R">Privacy Policy.</a>
              {/*<div className="body-R color-neutral-100">This site is protected by Google reCAPTCHA</div>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
      
export default OTPView;