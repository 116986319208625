import {useState, useEffect} from 'react';
import {getLocationLink, getTimeFromEpoc, isScheduleExpired} from '../../utils/util';
import {getTimeZoneKey} from '../../utils/DefaultStates';
import location_icon from '../../assets/Location pin.png';
import participants_icon from '../../assets/participants_icon.svg';

const WeekCard = (props) => {
	const {
		item = {}, 
		onHandleItemClick, 
		onHandleCTAClick, 
		activeTab = "-1",
		CTALabel = "", 
		isCTAShow = true,
		service_Type = "",
		isCTADisabled = false,
		isShowMeetingLink = false,
		isShowParticipantCount = false
	} = props;

	const {
		scheduleMode = "",
		availableSeatCount = 0,
		scheduleEndTime = "",
		scheduleStartTime = "",
		serviceType = "",
		scheduleBookedCount = -1,
		scheduleMaxParticipants = 0
	} = item || {};
	
	const {
        scheduleId = "",
        serviceName = "",
        serviceId = "",
        serviceDescription = "",
        day = "",
        serviceCategoryDetails = {},
        startTime = "",
        endTime = "",
        service_date = "",
        serviceScheduleDetails = {},
        serviceLocation = {},
        duration = "",
        booking_details = [],
        userDetails = {},
        serviceTimezone = ""
    } = item?.serviceDetails || {};

    const {
    	serviceMeetingLink = ""
    } = serviceScheduleDetails || {};
	
	const [isExpanded, setExpanded] = useState(false);
	const [isExpired, setExpired] = useState(false);

	useEffect(() => {
    	const isExp = isScheduleExpired(scheduleEndTime)
      	setExpired(isExp);
	}, []);

	const getCTALabel = (isExpired = false, avlCount = 0, label = "") => {
	    if(isExpired) return "Expired";
	    else if(avlCount === 0) return "Sold Out";

	    return label;
	}

	const isDisabledBtn = () => {
		if(isExpired || isCTADisabled || item?.isDisabled === "true" || availableSeatCount === 0) {
			return true;
		}
		return false;
	}

	const MaxTextValue = scheduleMaxParticipants == "-1" ? "Unlimited" : scheduleMaxParticipants;
	const CTALabelValue = getCTALabel(isExpired, availableSeatCount, CTALabel);
	const isDisabled = isDisabledBtn();

	return (
		<div className={`p-3 w-100 my-1 class-container py-lg-4 ${((item?.isDisabled)) ? 'disabled': ''}`}
			style={{ backgroundColor: CTALabel === 'JOIN' ? '#DBD1FF' : CTALabel === 'SUBSCRIBE' ? '#D4DCCE': '#F7E7D9' }}
		>
			
			<div className="padding desktop_view">
				<div className="flex_container gap_8">
					<div className="d-flex justify-content-between align-items-center caption-3 w-100">
						{serviceType !== "PRIVATE" && <div className="caption-3 color-primary-100">{startTime} - {endTime} {getTimeZoneKey(serviceTimezone)}</div>}
						{serviceType === "PRIVATE" && <div className="caption-3 color-primary-100">{getTimeFromEpoc(scheduleStartTime, serviceTimezone)} - {getTimeFromEpoc(scheduleEndTime, serviceTimezone)} {getTimeZoneKey(serviceTimezone)}</div>}

						<div className="color-primary-100">
							<div>
								<button 
									className="ax-button caption-3" 
									onClick={() => setExpanded(!isExpanded)} 
									disabled = {isDisabled}
								> 
									{isExpanded ? "-" : "+"} info
								</button>
							</div>
						</div>
					</div>

					<button 
						className="ax-button caption-3 color-primary-50" 
						onClick={() => onHandleItemClick(item)} 
						disabled = {isDisabled}
					>
						{userDetails?.userName?.toUpperCase() || "-"}
					</button>
					
					{/*<div className="caption-3 color-primary-50">{serviceCategoryDetails?.serviceCategoryName || "-"}</div>*/}
					
					<button 
						className="ax-button card_service_name" 
						onClick={() => onHandleItemClick(item)} 
						disabled = {isDisabled}
					>
						{serviceName}
					</button>
					
					{isShowParticipantCount && <div className="caption-3 color-primary-50 py-1">
						<img src={participants_icon} style={{width: "20px"}} />
						{`${scheduleBookedCount} / ${MaxTextValue}`} Participants
					</div>}

					{scheduleMode === "physical" &&
						<a href={getLocationLink(serviceLocation)} target="_blank">
							<div className="caption-2 color-primary-50 d-flex">
								<img src = {location_icon} style = {{width: '15px', height: '15px', marginTop: '0px'}} alt = "" />
								<h6 className="caption-3 color-primary-50">{serviceLocation?.city || scheduleMode}</h6>
							</div>
						</a>
					}
					{scheduleMode === "online" &&
						<div className="caption-2 color-primary-50 d-flex">
							<h6 className="caption-3 color-primary-50">Online</h6>
						</div>
					}
				</div>
			</div>

			<div className="padding mobile_view">
				<div className="d-flex justify-content-between subtitle-3 mb-2">
			
					{serviceType !== "PRIVATE" && <div className="caption-3 color-primary-100">{startTime} - {endTime} {getTimeZoneKey(serviceTimezone)}</div>}
					{serviceType === "PRIVATE" && <div className="caption-3 color-primary-100">{getTimeFromEpoc(scheduleStartTime, serviceTimezone)} - {getTimeFromEpoc(scheduleEndTime, serviceTimezone)} {getTimeZoneKey(serviceTimezone)}</div>}

					<div className="subtitle-3 color-primary-50">
						<div>
							<button 
								className="ax-button" 
								onClick={() => setExpanded(!isExpanded)}
								disabled={isDisabled} 
							> 
								{isExpanded ? "-" : "+"} info
							</button>
						</div>
					</div>
				</div>
				<button 
					className="ax-button caption-3 color-primary-50" 
					onClick={() => onHandleItemClick(item)} 
					disabled = {isDisabled}
				>
					{userDetails?.userName?.toUpperCase() || "-"}
				</button>
					
				<div>
					<h4 className="heading-4 color-primary-50">{serviceName}</h4>
				</div>

				{isShowParticipantCount && <div className="caption-3 color-primary-50 py-1">
						<img src={participants_icon} style={{width: "20px"}} />
						{`${scheduleBookedCount} / ${MaxTextValue}`} Participants
					</div>
				}

				<div className="py-2 d-flex justify-content-between align-items-center">
					<div>
						{scheduleMode === "physical" && <a href={getLocationLink(serviceLocation)} target="_blank">
							<div className="subtitle-5 color-primary-50 d-flex justify-content-between align-items-center">
								<img src={location_icon} style={{width: '24px', height: '24px'}} alt = "" />
								<div className="px-2 subtitle-5 color-primary-50">{serviceLocation?.city || scheduleMode}</div>
							</div>
						</a>}
						{scheduleMode === "online" &&
							<div className="py-2 color-primary-50 d-flex">
								<h6 className="subtitle-5 color-primary-50">Online</h6>
							</div>
						}
					</div>
				</div>
			</div>

			{(isExpanded && ((scheduleMode !== "online" && isShowMeetingLink) || !isShowMeetingLink)) && <div className="color-primary-50 subtitle-3 padding">{item?.serviceDetails?.serviceDescription || "-"}</div>}
			{isExpanded && scheduleMode === "online" && isShowMeetingLink && <a className="padding color-primary-50 subtitle-3" href={serviceMeetingLink} target="_blank">Meeting Link</a>}
			
			{isCTAShow && <div className="d-flex justify-content-center pt-3">
				<button 
					className="capitalize book_btn" 
					onClick={() => onHandleCTAClick(item)} 
					disabled = {isDisabled || (isShowParticipantCount && scheduleBookedCount === 0)}
				>
					{CTALabelValue || CTALabel}
				</button>
			</div>}
		</div>
	)	
}

export default WeekCard;