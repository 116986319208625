import "./dashbord_slider_item.css";

import NextImage from "../../assets/next.svg";
import React from "react";
const SliderItem = ({
  title,
  description,
  buttonText,
  logo,
  onChangeSliderIndex,
  onClickHandler,
}) => {
  return (
    <div className="slider-container">
      <img
        className="next-image"
        src={NextImage}
        onClick={onChangeSliderIndex}
      />
      <div
        className="slider-background-image"
        style={{
          backgroundImage: `url(${logo})`,
        }}
      />
      <div className="slider_item">
        <h2 className="slider-item-title">{title}</h2>
        <h2 className="slider-item-description">{description}</h2>

        <button className="button-slider-item" onClick={onClickHandler}>
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default SliderItem;
