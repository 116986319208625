import {groupById, pluralize} from '../../../../utils/util';

const PackageBookingListView = (props) => {

	const {onHandleScheduleNow,  list = [], onHandleBuyNowClick} = props;
	
	const groupList = groupById(list);

	return (
		<>
			{groupList.length !== 0 && groupList.map((eachBooking, indexVal) => {

			const {
				serviceDetails = {}
			} = eachBooking[0]?.schedule || {};

			const {serviceName = "", userDetails = {}} = serviceDetails || {};
			
			const totalOpenCount = eachBooking.filter(item => item.bookingDate === 0);
			const isBookingExpired = false; //Amit to change

			return (
				<>
					<div className="desktop_view1">
						<div className="d-flex align-items-center justify-content-between">
							<div>
								<div className="caption-R color-primary-50 py-1">{userDetails?.userName}</div>
								
								<div className="subtitle-B color-primary-50 text-left">
									{serviceName}
								</div>

								{<div className="caption-R color-primary-50 py-1">Remaining {totalOpenCount?.length}/{eachBooking?.length} {pluralize(totalOpenCount?.length, "session")}</div>}
							</div>

							{!isBookingExpired && <button disabled = {totalOpenCount?.length === 0} className="capitalize tertiary_btn color-primary-50" onClick={() => onHandleScheduleNow(eachBooking[0])}>
								Schedule
							</button>}

							{isBookingExpired && <button className="capitalize tertiary_btn color-primary-50" onClick={() => onHandleBuyNowClick(eachBooking[0].serviceId)}>
								renew Now
							</button>
							}

						</div>
					</div>

					{(indexVal !== (groupList.length - 1)) && 
						<div className="">
							<hr />
						</div>
					}
				</>
			)
		})}
		</>
	)
}

export default PackageBookingListView;