import {useEffect, useRef} from 'react';
import document from '../../../assets/document.png';
import partner_icon from '../../../assets/Partners.png';
import {GuidanceArray} from '../../../assets/data';

const GuidelineInfo = (props) => {
  const dialogRef = useRef();
	const {userDetails = {}, userErrors = {}, isGuidelineChecked = false, setGuidelineChecked } = props;

  const {
    guidelineError = false
  } = userErrors;

  useEffect(() => {
    try {
      const isEdit = userDetails && userDetails.userId !== "";
      setGuidelineChecked(true)
      dialogRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } finally {
    }
  }, []);

  

	return (
		<>
      <div className="container dialog_padding" ref={dialogRef}>
      	<div className="row">
      		<div className="col-lg-6 m-auto">
            <div className="flex_container_90">
        			<div className="flex_container_50">
                <div className="subtitle-R color-primary-50">We recommend that you read everything in detail before you start your application process.</div>
        				
                <div className="guideline_container">
                  {GuidanceArray.slice(0,2).map((item, index) => {
                    return (
                      <a href={item?.path} target="_blank" className="guideline_card">
                        <div className="d-flex justify-content-center">
                          <img src={partner_icon} style={{width: "30px", height: "30px"}} alt = "" />
                        </div>

                        <div className="subtitle-B color-primary-50 text-center">{item.title}</div>
                        <div className="caption-R color-primary-50 text-center py-2">{item.details}</div>
                      </a>
                    )
                  })}
                </div>
              </div>

              <div className="flex_container_50">
                <div className="subtitle-R color-primary-50">Do you still have questions or doubts?</div>
                <div className="guideline_container">
                   {GuidanceArray.slice(2,4).map((item, index) => {
                    return (
                      <a href={item?.href} target="_blank" className={`guideline_card ${index === 0 ? 'isDisabled' : ''}`}>
                        <div className="d-flex justify-content-center">
                          <img src={partner_icon} style={{width: "30px", height: "30px"}} alt = "" />
                        </div>
                        <div className="subtitle-B color-primary-50 text-center">{item?.title}</div>
                        <div className="caption-R color-primary-50 text-center py-2">{item?.details}</div>
                      </a>
                    )
                  })}
                </div>

                <div className="d-flex mt-3">
                  <input type="checkbox" id="agree" value={isGuidelineChecked} onChange = {e => setGuidelineChecked(!isGuidelineChecked)} checked = {isGuidelineChecked} />
                  <label className="ps-3 body-R color-primary-50" for="agree">I agree to the terms & conditions, and understand that my information will be processed for verification purpose.</label>
                </div>
                
                {guidelineError && <div className="body-R text-danger pt-1">! Please accept the terms & conditions</div> }
              </div>
        		</div>

      		</div>          
      	</div>
      </div>
    </>
	)
}

export default GuidelineInfo;