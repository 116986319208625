import "./minded-footer.css";
import Logo from "../../assets/Logo.svg";
import MOB from "../../assets/MOB.svg";
import React from "react";

const MindedFooter = () => {
  return (
    <div className="footer-container">
      <img src={Logo} className="logo-footer" />

      <div className="powered-by-container">
        <span className="powered-by">Powered by</span>
        <img src={MOB} alt="MOB" className="mob" />
      </div>
      <div className="politica-by-container">
        <span className="politica-by">Política de Privacidade</span>
      </div>
    </div>
  );
};

export default MindedFooter;
