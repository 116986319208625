import "./growth-journey-item.css";
import CheckMark from "../../assets/Checkmark.svg";
import React from "react";
const GrowthJourneyItem = ({
  growth,
  isSelected,
  onHandlePlanDetailsChange,
  onClickHandler,
  isLoggedIn
}) => {
  return (
    <div
      className={`growth-item-container ${
        growth.yearly
          ? "yearly"
          : growth.quarterly
          ? "quarterly"
          :""
      }`}

      style={{
        opacity: isSelected ? 0.5 : 1
      }}
    >
      {(growth.yearly || growth.quarterly || growth.isSelected) && (
        <span
          className="limited-time-offer"
          style={{
            backgroundColor: growth.yearly ? "#739E83" : "#dbd1ff",
            color: growth.yearly ? "white" : "#245938",
          }}
        >
          {growth.quarterly
            ? "QUARTERLY"
            : growth.yearly
            ? "YEARLY"
            : "BEST VALUE"}
        </span>
      )}
      <span className="growth-item-title">{growth.title}</span>
      <span className="growth-item-title-description">
        {growth.titleDescription}
      </span>
      <img className="growth-image-item" src={growth.icon} alt="icon-item" />
      {growth.previousPrice && (
        <div className="growth-journey-price-container">
          <span className="growth-journey-previous-price">
            {growth.previousPrice} EUR
          </span>
          <span className="growth-journey-previous-price-monthly">
            {growth?.quarterly
              ? " / quarter"
              : growth?.yearly
              ? " / yearly"
              : " / month"}{" "}
          </span>
        </div>
      )}
      <div className="growth-journey-price-container">
        <span className="growth-journey-price">{growth.price} EUR</span>
        <span className="growth-journey-price-monthly">
          {growth?.quarterly
            ? " / quarter"
            : growth?.yearly
            ? " / yearly"
            : " / month"}{" "}
        </span>
      </div>
      {growth.features && (
        <div className="separator-container">
          <div className="separator"></div>
          {growth.features?.map((feature) => {
            return (
              <div className="feature-container">
                <img
                  className="check-mark-image"
                  src={CheckMark}
                  alt="check mark"
                />
                <span className="feature-text">{feature}</span>
              </div>
            );
          })}
        </div>
      )}

      {growth.additionalFeatures && (
        <div className="separator-container">
          <div className="separator"></div>
          <div className="feature-title-container">
            <img
              src={CheckMark}
              alt="check mark"
              className="check-mark-image"
            />
            <span className="feature-text-title">{growth.featureTitle}</span>
          </div>
          <div className="separator"></div>

          <div className="addition-text-container">
            <span className="addition-text">Additionally: </span>

            {growth.additionalFeatures?.map((additionalFeature) => {
              return (
                <div className="additional-feature-container">
                  <img
                    className="check-mark-image"
                    src={CheckMark}
                    style={{
                      alignSelf: additionalFeature?.descriptions
                        ? "flex-start"
                        : "",
                      marginTop: additionalFeature?.descriptions && "1.5rem",
                    }}
                    alt="check mark"
                  />
                  <div className="additional-features-description-container">
                    <span className="feature-text">
                      {additionalFeature.title}
                    </span>
                    {additionalFeature?.descriptions && (
                      <ul>
                        {additionalFeature?.descriptions.map((description) => {
                          return <li>{description}</li>;
                        })}
                      </ul>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}


      <button hidden={isLoggedIn} 
        className="button-select"
        onClick={() => {
          if(!isLoggedIn && !onHandlePlanDetailsChange){
            onClickHandler();
          }
          onHandlePlanDetailsChange?.(growth)
        }}
      >
        {isSelected ? 'SELECTED': 'SELECT'}
      </button>
    </div>
  );
};

export default GrowthJourneyItem;
