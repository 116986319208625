import React, { useEffect, useState } from "react";
import BecomeVersionOfYourself from "./BecomeVersionOfYourself";
import Growth from "./Growth";
import SliderDashboard from "./SliderDashboard";
import GrowthJourney from "./GrowthJourney";
import FrequentlyAskedQuestions from "./FrequentlyAskedQuestions";
import MindedFooter from "./MindedFooter";
import { useNavigate } from "react-router-dom";
import AuthDialog from "../../components/authenticate/AuthDialog";
import { useDispatch, useSelector } from "react-redux";
import { saveAdmin } from "../../actions/admin.actions";
import { InitialUserDetails } from "../../utils/DefaultInitialValues";
import OnBoardingDialog from "../admin/onBoardingAdmin/OnBoardingDialog";
import GrowtholicPlan from "./GrowtholicPlan";

const NewHome = () => {
  const [isShowLoginDialog, setShowLoginDialog] = useState(false);
  const [isShow, setShow] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [userDetails, setUserDetails] = useState(InitialUserDetails);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const startNowHandler = () => {
    console.log('open dialog')
    setShowLoginDialog(true);
  };

  const onLoginSuccess = (isSignupView = false, userinfo = {}) => {
    setShowLoginDialog(false);
    if (isSignupView) {
      setUserDetails(userinfo);
      setShow(true);
    } else {
      setLoggedIn(true);
      dispatch(saveAdmin(userinfo));
      navigate("/dashboard");
    }
  };

  const {
    details: loggedUser
  } = useSelector(({ loggedAdmin: { details} }) => ({ details }));

  useEffect(() => {
  	setUserDetails(loggedUser);
  }, [loggedUser]);

  const isUserSignedIn = userDetails && userDetails?.userId && userDetails?.userId !== "";

  return (
    <div className="app-container">
      <BecomeVersionOfYourself />
      <Growth onClickHandler={startNowHandler} />
      <SliderDashboard onClickHandler={startNowHandler} />
      <GrowtholicPlan/>
      <GrowthJourney onClickHandler={startNowHandler} isLoggedIn={isUserSignedIn} />
      <FrequentlyAskedQuestions onClickHandler={startNowHandler} />
      <MindedFooter />

      {isShowLoginDialog && (
        <AuthDialog
          isOpen={isShowLoginDialog}
          onHandleClose={(val) => setShowLoginDialog(false)}
          onLoginSuccess={(isSignupView, userinfo) =>
            onLoginSuccess(isSignupView, userinfo)
          }
          isShowSignUp={true}
          userinfo={{}}
        />
      )}
      {isShow && (
        <OnBoardingDialog
          isOpen={isShow}
          onHandleClose={() => setShow(false)}
          setLoggedIn={(val) => setLoggedIn(val)}
          userinfo={userDetails}
        />
      )}
    </div>
  );
};

export default NewHome;
