import React, {useState, useEffect} from 'react';
import {getFormattedDate, isEmptyJson} from '../../utils/util';
import {useDispatch, useSelector} from 'react-redux';
import {saveFilters} from '../../actions/filters.actions';

import './radio-card.css';

const RadioCard = (props) => {
	const dispatch = useDispatch();
	const saveFiltersAction = saveFilters;
	const {index = 0, label = "", name = "", currentDay = "", isDayView = "false", startDate} = props;
	const [day, setDay] = useState("Sun");

	const [filters, setFilters] = useState({});

	const filterDetails = useSelector(state => state.filter.details);

	const dayClick = () => {
		
		setFilters({...filters, ["startDate"]: currentDay});
		const obj = filters;
		obj.startDate = new Date(currentDay);
		dispatch(saveFiltersAction(obj));
	}

	 useEffect(() => {
    	if(!isEmptyJson(filterDetails)) {
    		setFilters(filterDetails);
    	}
    }, [filterDetails]);

	useEffect(() => {
		let day_val = "Sun";

		switch(index) {
			case 0:
				day_val = "Sun"
			break;
			case 1:
				day_val = "Mon"
			break;
			case 2:
				day_val = "Tue"
			break;
			case 3:
				day_val = "Wed"
			break;
			case 4:
				day_val = "Thu"
			break;
			case 5:
				day_val = "Fri"
			break;
			case 6:
				day_val = "Sat"
			break;
			default: break;
		}

		setDay(day_val);
	}, []);

	const dayVal = currentDay && currentDay.split("-")[2];
	const todayFormmattedDate = getFormattedDate(startDate);

	return (
		<div className="radio-card w-100">
			<input type="radio" name={name} className="a11y" id={index} />
			<label for={isDayView === "true" ? index : ""} onClick = {dayClick}>
				<div className="radio_label_container w-100 text-center w-100">
					<div className="py-lg-2 py-md-2 py-1 color-primary-50 subtitle-B day_item">{day}</div>
					<div className="py-lg-2 py-md-2 color-primary-50 subtitle-B">{dayVal}</div>
				</div>
				{(isDayView === "true" && todayFormmattedDate === currentDay) ?  <i className='dot pt-2'></i> : null}
			</label>
		</div>
	)	
}

export default RadioCard;