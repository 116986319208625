import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {pluralize} from '../../../../utils/util';
import {InitialPackageDetails, headerOption} from '../../../../utils/DefaultInitialValues';

import CustomizedSnackbars from  '../../../../components/CustomizedSnackbars';
import UpdatePackage from './settings-view/UpdatePackage';
import Loading from '../../../../components/loading/Loading';
import {baseURL} from '../../../../actions/Constants';

import {
  getAuthenticateHeader
} from '../../../../utils/util';

const PackageDetails = () => {
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const {
    	details: loggedUser
    } = useSelector(({ loggedAdmin: { details} }) => ({ details }));

	const [isShowPackageDialog, setShowPackageDialog] = useState(false);
	const [packageDetails, setPackageDetails] = useState(InitialPackageDetails);
	const [packageList, setPackageList] = useState([]);

	const addPackage = () => {
		setPackageDetails(InitialPackageDetails);
		setShowPackageDialog(true);
	}

	const onPackageClick = (item = {}) => {
		const filterItemArray = packageList.filter((pck, index) => pck.id === item.id);

		if(filterItemArray.length > 0) {
			setPackageDetails(filterItemArray[0]);
			setShowPackageDialog(true);
		}
	} 

	const fetchPackages = async () => {
		const URL = `${baseURL}/minded-services/getPackagesByUserId/${loggedUser?.userId}`;
		setLoading(true);

		const myHeaders = getAuthenticateHeader();

		return await fetch(URL, headerOption)
	    .then(response => {
		    const {status = ""} = response || {};
		    setLoading(false);

		    if(status === 200 || status === 201) {
		      return response.json().then(res => { 
		      	setPackageList(res);
		      });
		    } else {
		      	//Error
	    		setError(true);
		    }
		  })
		.catch((error) => {
			console.log('error addService/updateService', error);
			setError(true);
	    	setLoading(false);
		});
	}

	const onUpdateSuccess = () => {
		fetchPackages();
	}

	useEffect(() => {
		fetchPackages();
	}, []);

	return (
		<div className="row">
			<div className="col-lg-8 m-auto">
				<div className="bg-white rounded-3 p-4 my-5">
					<h4 className="heading-4 color-primary-50">Packages</h4>
					{packageList.length === 0 && <div className="d-flex flex-column align-items-center justify-content-center my-5">
						<div className="color-primary-50 body-R">There is no package added yet. Please add a Package!</div>
					</div>}

					<div className="w-100 d-flex justify-content-center my-3">
						<button className="tertiary_btn capitalize width-50 m-auto color-primary-50" onClick={addPackage}>Add Package</button>
					</div>

					{packageList.length > 0 && packageList.map((pack, index) => 
						<div>
							<div className="my-1 p-2">
			                   <div className="caption-2 py-1 color-primary-50">Valid for {pack?.packageValidityDuration || "-"} {pluralize(pack?.packageValidityDuration, "month")} </div>
			                   
			                    <div className="d-flex justify-content-between align-items-center">
				                    <button className="ax-button d-flex flex-column " onClick = {() => onPackageClick(pack)}>
				                    	 <h5 className="heading-5 color-primary-50">{pack?.packageName || "-"}</h5>
					                    <div>  
					                      <div className="color-primary-50 body-R">{pack?.noOfSessions} {pluralize(pack?.noOfSessions, "Session")} - {pack?.packagePrice} EUR</div>
					                    </div>
				                    </button>
				                    <div>
				                      <button className="ax-button color-secondary-100 fw-bold fs-5" onClick= {() => setPackageDetails(pack)}>...</button>
				                    </div>
			                  	</div>
	            			</div>

	        				{(index !== packageList.length - 1) && <hr />}
						</div>
					)}
					
				</div>
			</div>
			{isShowPackageDialog && <UpdatePackage 
				isOpen = {isShowPackageDialog} 
				details = {packageDetails}
				onUpdateSuccess = {onUpdateSuccess}
				handleClose = {() => setShowPackageDialog(false)} />
			}

			{isLoading && <Loading />}
			{isError && <CustomizedSnackbars isShow={isError} text="Something went wrong. Please try after sometime." showAlert={(isHide) => setError(false)} />}
		</div>
	)
}

export default PackageDetails;
