import React, {useState, useEffect} from 'react';
import { useLocation, Link } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import { Breadcrumb } from "rsuite";
import {getDateFromEpoc} from '../../../../../utils/util';
import CustomizedSnackbars from  '../../../../../components/CustomizedSnackbars';

import {getServices} from '../../../../../actions/services.actions';
import Loading from '../../../../../components/loading/Loading';
import Table from '../../../../../components/table/Table';
import right_arrow from '../../../../../assets/thin arrow - right.png';

const AllServices = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const [crumb, setCrumb] = useState([]);

	const getServicesAction = getServices;

	const {
       details,
       loading,
       error
    } = useSelector(({ services : { details, loading, error} }) => ({ details, loading, error }));

    const [allServices, setServices] = useState([]);

    useEffect(() => {
    	if(details && Array.isArray(details)) {
    		setServices(details);
    	}
    }, [details]);

	useEffect(() => {
        let locationVal = location?.pathname;
        const crumbs = location.pathname.split("/");
        createCrumbDetails(crumbs);
    }, [location]);

    useEffect(() => {
    	dispatch(getServicesAction("", "", "", "", "",  "", "active"));
    }, []);

    const columns = [
	    {
	      	name: "serviceName",
	      	label: 'Name',
	      	options: {
	      		filter: true,
	       		sort: true,
	      	}
	    },
	    {
	      	name: "serviceType",
	      	label: 'Type',
	      	options: {
	       		filter: true,
	       		sort: true,
	      	}
	    },
	    {
	      	name: "serviceStartDate",
	      	label: 'Start Date',
	      	options: {
	       		filter: true,
	       		sort: true,
	       		customBodyRender: (value, tableMeta, updateValue) => {
	              	return (
		                <div className="ax-button color-primary-50">
		                  {getDateFromEpoc(value)}
		                </div>
	              	);
          		}
	      	},
	    },
	    {
	      	name: "serviceEndDate",
	      	label: 'End Date',
	      	options: {
	       		filter: true,
	       		sort: true,
	       		customBodyRender: (value, tableMeta, updateValue) => {
	              	return (
		                <div className="ax-button color-primary-50">
		                  {getDateFromEpoc(value)}
		                </div>
	              	);
          		}
	      	},
	    },
	    {
	      	name: "serviceStatus",
	      	label: 'Status',
	      	options: {
	       		filter: true,
	       		sort: true,
	       		customBodyRender: (value, tableMeta, updateValue) => {
	              	return (
		                <div className="ax-button color-primary-50">
		                  {value ? value?.toUpperCase() : "-"}
		                </div>
	              	);
          		}
	      	},
	    },	    
	];

	const options = {
	    selectableRows: false,
	    filter: false,
	    download: true,
	    print: true,
	    rowsPerPage: 100,
	    sortOrder: {
	      name: '',
	      direction: 'asc'
	    }
  	};

	const createCrumbDetails = (crumbs) => {
		const crumbArray = [];
		
		crumbs.map((crmb, index) => {
			const obj = {};

			switch(crmb) {
				case "":
					obj.path = "/";
					obj.label = "Home";
					crumbArray.push(obj);
					break;
				case "dashboard":
					obj.path = "/dashboard";
					obj.label = "My Dashboard";
					crumbArray.push(obj);
					break;
				case "services":
					obj.path = "/dashboard/services";
					obj.label = "All Services";
					crumbArray.push(obj);
					break;
				default: break;
			}

			setCrumb(crumbArray);
		});
	}

	return (
		<div className="main-no-content-padding">
			<Breadcrumb> 
                {crumb.map((item, index) => {
                	return (index !== crumb.length - 1) ? (
	                	<>
		                	<Link to={item.path}>
		                		<span className={`body-R color-secondary-100 px-2`}>{item.label}</span>
		                	</Link>
		                	<img src={right_arrow} style={{width: '12px', height: '12px'}} alt = "" />
	                	</>
	                	) : (<span className={`body-R color-neutral-50 px-2`}>{item.label}</span>)
                	}
                )}
	        </Breadcrumb>

	        <h1 className="heading-1 color-primary-50">All Services</h1>
			
			<div className="my-5">
				<Table headers={columns} rowData={allServices} options = {options} />
			</div>

			{loading && <Loading />}
			{error && <CustomizedSnackbars isShow={error} text="Something went wrong. Please try after sometime." showAlert={(isHide) => console.log("")} />}
		
		</div>
	)
}

export default AllServices;