import EachScheduleDetails from './EachScheduleDetails';

const ScheduleListView = (props) => {
	const {list = []} = props;
	return (
		<>
			{list?.length === 0 && <h3 className="color-primary-50 text-center my-5">No order found</h3>}
			{list?.length > 0 &&  list.map((eachSch, indexVal) =>
				<> 
					<EachScheduleDetails sch = {eachSch} />
					{indexVal !== (list.length - 1) && 
						<div className="">
							<hr />
						</div>
					}
				</>
			)}
		</>
	)
}

export default ScheduleListView;