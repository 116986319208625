import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {pluralize} from '../../../../utils/util';
import UpdatePlan from './settings-view/UpdatePlan';
import {InitialPlanDetails, headerOption} from '../../../../utils/DefaultInitialValues';

import Loading from '../../../../components/loading/Loading';
import {baseURL} from '../../../../actions/Constants';

import {
  getAuthenticateHeader
} from '../../../../utils/util';


const PlanDetails = () => {
	const [isLoading, setLoading] = useState(false);
  	const [isError, setError] = useState(false);

  	const [isShowPlanDialog, setShowPlanDialog] = useState(false);
	const [planDetails, setPlanDetails] = useState(InitialPlanDetails);
	const [planList, setPlanList] = useState([]);

	const {
    	details: loggedUser
    } = useSelector(({ loggedAdmin: { details} }) => ({ details }));

	const addPlan = () => {
		setPlanDetails(InitialPlanDetails);
		setShowPlanDialog(true);
	}

	const onPlanClick = (item = {}) => {
		const filterItemArray = planList.filter((plan, index) => plan.id === item.id);

		if(filterItemArray.length > 0) {
			setPlanDetails(filterItemArray[0]);
			setShowPlanDialog(true);
		}
	}

	const fetchPlans = async () => {
		const URL = `${baseURL}/minded-services/getPlansByUserId/${loggedUser?.userId}`;
		setLoading(true);

		const myHeaders = getAuthenticateHeader();

		return await fetch(URL, headerOption)
		.then((res) => {
            //Close Dialog
            setLoading(false);
            const {status = ""} = res;

            if(status === 200 || status === 201) {
            	return res.json().then(res => {
	      			
	      			setPlanList(res);
		      	});
            } else {
            	setError(true);
            }
        })
        .catch((error) => {
            console.log('error fetchPlans', error);
            setLoading(false);
            setError(true); 
        });
	}

	const onUpdateSuccess = () => {
		fetchPlans();
	}

	useEffect(() => {
		fetchPlans();
	}, []);

	return (
		<div className="row">
			<div className="col-lg-8 m-auto">
				<div className="bg-white rounded-3 p-4 my-5">
					<h4 className="heading-4 color-primary-50">Plans</h4>
					{planList.length === 0 && <div className="d-flex flex-column align-items-center justify-content-center my-5">
						<div className="color-primary-50 body-R">There is no plan added yet. Please add a Plan!</div>
					</div>}
					
					<div className="w-100 d-flex justify-content-center my-3">
						<button className="tertiary_btn capitalize width-50 m-auto color-primary-50" onClick={addPlan}>Add New Plan</button>
					</div>

					{planList.length > 0 && planList.map((plan, index) =>
						<div>
							<div className="my-1 p-2">
			                   <div className="caption-2 py-1 color-primary-50">Valid for {plan?.planValidityDuration || "-"} {pluralize(plan?.planValidityDuration, "month")}</div>
			                    <div className="d-flex justify-content-between align-items-center">
				                    <button className="ax-button d-flex flex-column " onClick = {() => onPlanClick(plan)}>
				                    	<h5 className="heading-5 color-primary-50">{plan?.planName || "-"}</h5>
					                    <div>  
					                      <div className="color-primary-50 body-R">{plan?.planPrice} EUR / {plan.planPaymentPeriod || "month"}</div>
					                    </div>
				                    </button>
				                    <div>
				                      <button className="ax-button color-secondary-100 fw-bold fs-5" onClick= {() => setPlanDetails(plan)}>...</button>
				                    </div>
			                  	</div>
	            			</div>

	        				{(index !== planList.length - 1) && <hr />}
						</div>	
					)}
				</div>
			</div>
			{isShowPlanDialog && <UpdatePlan 
				isOpen = {isShowPlanDialog} 
				details = {planDetails}
				onUpdateSuccess = {onUpdateSuccess}
				handleClose = {() => setShowPlanDialog(false)} 
			/>}

			{isLoading && <Loading />}
		</div>
	)
}

export default PlanDetails;
