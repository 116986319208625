import {useState} from 'react';
import {getDateFromEpoc, CalculateTimeStampDifference} from '../../../../utils/util';
import ParticipantDialog from './ParticipantDialog';
import participants_icon from '../../../../assets/participants_icon.svg';

const EachScheduleDetails = (props) => {

	const {sch = {}} = props;
	const [isShowScheduleDialog, setShowScheduleDialog] = useState(false);

	const {
		scheduleStartTime = 0,
		scheduleEndTime = 0,
		serviceId = "",
		scheduleId = "",
		scheduleDate = "",
		scheduleMode = "",
		serviceType = "",
		serviceDetails = {},
		scheduleMaxParticipants = -1,
		availableSeatCount = 0,
		scheduleBookedCount = 0
	} = sch || {};

	const {
		serviceName = ""
	} = serviceDetails || {};

	//console.log(sch);

	const MaxTextValue = scheduleMaxParticipants == "-1" ? "Unlimited" : scheduleMaxParticipants;
	const seatBookedCount = scheduleBookedCount || 0;

	const onScheduleClick = () => {
		if(seatBookedCount !== 0) {
			setShowScheduleDialog(true);
		}
	}

	return (
		<>
		<div className="d-flex align-items-center justify-content-between">
			<div>
				<div className="caption-R color-primary-50 py-1">{getDateFromEpoc(scheduleDate)} | {CalculateTimeStampDifference(scheduleStartTime,scheduleEndTime)} | {scheduleMode.toUpperCase()}</div>
				
				<button 
					className="ax-button subtitle-B color-primary-50 text-left" 
					onClick={onScheduleClick}
				>
					{serviceName} | {serviceType}
				</button>
				<div className="caption-R color-primary-50 py-1">
					<img src={participants_icon} style={{width: "40px"}} />
					{`${seatBookedCount} / ${MaxTextValue}`} Participants
				</div>
			</div>
			<button className="ax-button" onClick={onScheduleClick} disabled={seatBookedCount === 0}>...</button>
		</div>
		{isShowScheduleDialog && <ParticipantDialog isOpen = {isShowScheduleDialog} scheduleId = {scheduleId} onHandleClose = {() => setShowScheduleDialog(false)} />}
		</>
	)
}

export default EachScheduleDetails;