import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./growtholicplan.css";

import React from "react";
import { growtholicPlans } from "../../actions/Constants";
import { useMediaQuery } from "@mui/material";

const GrowtholicPlan = () => {
  const isSmallDevice = useMediaQuery('(max-width:600px)')
  console.log("isSmallDevice", isSmallDevice);
  

  var settings = {
    dots: false,
    infinite: false,
    speed: 700,
    arrows: false,
    slidesToShow: isSmallDevice ? 2 : 6,
  };
  return (
    <div className="gorwtholic-plan-container">
      
      <div className="gorwtholic-plan-title-container-parent">
        <div className="gorwtholic-plan-title-container">
          <span className="gorwtholic-plan-title">Some of our classes</span>
          <span className="gorwtholic-plan-title-description">
            (Included in the Growthaolic plan)
          </span>
        </div>
      </div>
      <div className="growth-plan-slider-container">
        <Slider {...settings}>
          {growtholicPlans.map((growth) => {
            return (
              <div className="gorwtholic-item">
                <span className="gorwtholic-item-title">{growth.title}</span>
                <img src={growth.image} className="growtholic-image" />
                <span className="gorwtholic-item-description">
                  {growth.description}
                </span>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default GrowtholicPlan;
