import {useState, useEffect} from 'react';

import Textarea from '../../../../components/textarea/Textarea';
import { styled } from '@mui/material/styles';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import {
 	InitialTicketDetails,
 	Ticket_Price_Method,
 	ticketQuantityType_array,
 	Ticket_Participant_Array,
 	initialPackageErrors
} from '../../../../utils/DefaultInitialValues';

import FloatSelectView from '../../../../components/floatSelectView/FloatSelectView';
import Input from '../../../../components/input/Input';

import brown_cancel from '../../../../assets/brown_cancel.png';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const EventPackageDialog = (props) => {
	const {
		maxParticipant = -1,
	    eachTicket = {},
	    isOpen = false, 
	    handleClose,
	    onAddPackage
  	} = props;

  	const isEdit = (eachTicket && eachTicket?.tickets && eachTicket?.tickets?.id !== "");
	const [errors, setErrors] = useState(initialPackageErrors);

	const [priceDetails, setPriceDetails] = useState(InitialTicketDetails);
	const [open, setOpen] = useState(false);
	const [fixedPrice, setFixedPrice] = useState("");
	const [optionalPrice, setOptionalPrice] = useState("");
	const [differentPriceList, setDifferentList] = useState([]);
	const [isFormValid, setFormValid] = useState(true);

	const {
		ticketName = "",
		ticketDescription = "",
		ticketQuantityType = "limited",
		ticketMaxParticipant = -1,
		packageType = "Fixed"
	} = priceDetails || {};

	const onUpdatePriceInfo = (e) => {
		let {name, value} = e?.target || {};
		if(name === "maxParticipant") {
			value = parseInt(value);
		}
		setPriceDetails({...priceDetails, [name] : value});
	}

	const handleClickOpen = () => {
		setOpen(true);
	};

	useEffect(() => {
		setOpen(isOpen);
	}, [isOpen]);

	useEffect(() => {
		if(isEdit && eachTicket && eachTicket?.tickets) {
			setPriceDetails(eachTicket?.tickets);
			if(eachTicket?.tickets?.packageType === "Fixed") {
				setFixedPrice(eachTicket?.tickets?.ticketList[0]?.packagePrice);
			} else if(eachTicket?.tickets?.packageType  === "Pay as you want") {
				setOptionalPrice(eachTicket?.tickets?.ticketList[0]?.packagePrice);
			} else if(eachTicket?.tickets?.packageType  === "Variant") {
				setDifferentList(eachTicket?.tickets?.ticketList);
			}
		}
	}, [eachTicket]);

	useEffect(() => {
		if(packageType === "Variant" && differentPriceList.length === 0) {
			addDifferentPrice();
		}
	}, [packageType]);

	const addDifferentPrice = () => {
		const diff_values = [...differentPriceList];
		
		diff_values.push({
			packageName: "",
			packagePrice: ""
		});
		setDifferentList(diff_values);
	}

	const onDifferentValueUpdate = (e, index = 0) => {
    	const newArray = [...differentPriceList];
    	newArray[index][e.target.name] = e.target.value;
    	setDifferentList(newArray);
  	}

	const removeItem = (item = {}, delInx = 0) => {
		const newList = differentPriceList.filter((item, index) => index !== delInx);
		setDifferentList(newList);
	}

	const onSubmit = () => {
		const isValid = validate();

		if(isValid) {
			const  newArray = [];
			const obj = {};

			if(packageType === "Fixed") {
				obj.packageName = packageType;
				obj.packagePrice = fixedPrice;
				newArray.push(obj);
			} else if(packageType === "Pay as you want") {
				obj.packageName = packageType;
				obj.packagePrice = optionalPrice;
				newArray.push(obj);
			} else {
				differentPriceList.map((item, index) => {
					const diff_obj = {};
					diff_obj.packageName = item.packageName;
					diff_obj.packagePrice = item.packagePrice;
					newArray.push(diff_obj);
				});
			}

			priceDetails.ticketList = newArray;
			
			onAddPackage(priceDetails);
			handleClose();
		}
	}

	const validate = () => {
		let isValid = true;
		let isPackageNameInValid = false;
		let isPackageMaxParticipantInValid = false;
		let isPackageTypeInvalid = false;

		let isFixedPriceInValid = false;
		let isOptionalPriceInValid = false;
		let variantErrors = [];

		if(ticketName === "") {
			isPackageNameInValid = true;
			isValid = false;
		}

		if(ticketQuantityType === "limited") {
			if(ticketMaxParticipant === "" || parseInt(ticketMaxParticipant) === -1 || parseInt(ticketMaxParticipant) > parseInt(maxParticipant)) {
            
				isPackageMaxParticipantInValid = true;
				isValid = false;
			}
		}

		if(packageType === "") {
			isPackageTypeInvalid = true;
			isValid = false;
		}

		if(packageType === "Fixed") {
			if(fixedPrice === "") {
				isFixedPriceInValid = true;
				isValid = false;
			}	
		} else if(packageType === "Pay as you want") {
			if(optionalPrice === "") {
				isOptionalPriceInValid = true;
				isValid = false;
			}	
		} else if(packageType === "Variant") {
			differentPriceList.map((item, index) => {
				const obj = {};
				obj.packageName = (item.packageName === "");
				obj.packagePrice = (item.packagePrice === "");
				if(item?.packagePrice === "" || item?.packageName === "") {
					isValid = false;
				}
				variantErrors.push(obj);
			});
		}

		setErrors({...errors, 
			ticketName_error : isPackageNameInValid,
			maxParticipant_error: isPackageMaxParticipantInValid,
			packageType_error: isPackageTypeInvalid,
			fixed_price_error: isFixedPriceInValid,
			variant_package_errors: variantErrors,
			optional_price_error: isOptionalPriceInValid
		});

		setFormValid(isValid);
		return isValid;
	}

	const {
		ticketName_error = false,
		maxParticipant_error = false,
		packageType_error = false,
		fixed_price_error = false,
		optional_price_error = false,
		package_price_error = false,
		variant_package_errors = []
	} = errors || {};

  	return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className="bg-white">
          	<div className="w-100 d-flex justify-content-between align-items-center">
		        <div>
		        </div>

		        <h4 className="heading-4 color-primary-50">
		          Ticket
		        </h4>

		        <div>
		          <button
		            onClick={handleClose}
		            aria-label="close"
		            className="ax-button"
		          >
		            <img src={brown_cancel} className="close_icon" />
		          </button>
		        </div>
		    </div>
        </DialogTitle>

        <DialogContent dividers className="bg-white">
      		<div className="container my-3">
		      	<div className="row">
			        <div className="col-lg-12 m-auto">
			          	<div className="w-100 card-view bg-white flex_container_70 bg-white border_50">
				          	<div className="flex_container_50 w-100">
					            <div className="w-100">
					              <Input
					                name="ticketName"
					                label="Ticket name"
					                placeHolder="xxx" 
					                value={ticketName}
					                onInputChange={(e) => onUpdatePriceInfo(e)} 
					              />
					              {ticketName_error && <div className="text-danger pt-1">! Ticket name is Invalid</div>}
					            </div>
					            
					            <div>
					              <label for="ticketDescription" className="color-primary-50 mb-2">Description (optional)</label>
					              <Textarea 
					                id="ticketDescription" 
					                name="ticketDescription" 
					                value={ticketDescription} 
					                type="text" className="w-100" 
					                rows="4" 
					                cols="100" 
					                placeholder="write description with max 3000 words."
					                onInputChange={(e) => onUpdatePriceInfo(e)}
					               />
					              
					            </div>
				            </div>

				            <div className="flex_container_50 w-100">
				            	<h5 className="heading-5 color-primary-50">Ticket Quantity</h5>
			              	
				              	<div className="d-flex w-100">
					              	{ticketQuantityType_array.map((item, index) => {
					                  return (
					                    <div className="d-flex">
					                      <input
					                        id={item.label}
					                        name = "ticketQuantityType"
					                        type = "radio" 
					                        className = "" 
					                        value = {item.value}
					                        disabled = {index === 1 && parseInt(maxParticipant) !== -1}
					                        checked = {item.value === ticketQuantityType} 
					                        onChange = {e => onUpdatePriceInfo(e)}
					                      />
					                      <label for={item.label} className="px-4 color-primary-50">
					                        <div>{item.label}</div>
					                      </label>
					                    </div>
					                  )
					                }
					                )}
				                </div>

			              		{ticketQuantityType === "limited" && 
				              		<>
						              	<div className="w-100">
						              		<label for="services" className="heading-6 color-primary-50 my-2">Maximum Tickets</label>
						              		<div className="d-flex w-100">
								                {Ticket_Participant_Array.map((item, index) => {
								                  return (
								                    <div className="color-secondary-90_bg me-3 rounded-2">
								                      <input
								                        id={item.label}
								                        name = "ticketMaxParticipant"
								                        type = "radio" 
								                        className = "a11y participant" 
								                        value = {item.value}
								                        checked = {item.value == ticketMaxParticipant} 
								                        onChange = {e => onUpdatePriceInfo(e)}
								                      />
								                      <label for={item.label} className="px-4 py-3 ">
								                        <div>{item.label}</div>
								                      </label>
								                    </div>
								                  )
								                }
								                )}
							                </div>

							                <div className="w-100 mt-3">
								              <Input
								                name="ticketMaxParticipant"
								                label="Custom"
								                placeHolder="xxx" 
								                value={ticketMaxParticipant}
								                type="number"
								                onInputChange={(e) => onUpdatePriceInfo(e)} 
								              />
					            			</div>

							                {maxParticipant_error && <div className="text-danger pt-1"> ! Maximum participants should not be blank or more than seat count</div>}
						              	</div>
				            		</>
			              		}
			              		{ticketQuantityType !== "limited" && <div className="body-R color-primary-50">Unlimited tickets will be available</div>}
			            	
				            </div>

				            <div className="flex_container_50 w-100">
				            	<h5 className="heading-5 color-primary-50">Ticket Pricing</h5>

				            	<div className="w-100 select">
					              	<FloatSelectView
						                name="packageType"
						                label = "Pricing method"
						                value={packageType}
						                list = {Ticket_Price_Method} 
						                onInputChange = {e => onUpdatePriceInfo(e)} 
					              	/>
					              	{packageType_error && <div className="text-danger pt-1">Pricing Method is a required field</div>}

					              	{packageType === "Pay as you want" &&
						                <p className="caption-R color-primary-50">Set the minimum price guests must pay for a ticket.</p>
					                }
					            </div>

					            <div>
					                {packageType === "Variant" && 
						                <>
						                  <label className="heading-6 color-primary-50">Pricing Options</label>
						                  <div className="body-R color-primary-50">Add up to 10 different price options. On your site, the first option is shown as the standard price. Other options appear in a drop down.</div>
						                </>
					                }
					            </div>
				            
					            {packageType === "Fixed" && <div className="w-100">
				              		<Input
						                name="fixedPrice"
						                label="Ticket price (in EUR)"
						                placeHolder="xxx" 
						                value={fixedPrice}
						                type="number"
						                onInputChange={(e) => setFixedPrice(e.target.value)} 
				              		/>
				              		
				              		{fixed_price_error && <div className="text-danger pt-1">Ticket Price is a required field</div>}
					            	</div>
					        	}

					        	{packageType === "Pay as you want" && <div className="w-100">
				              		<Input
						                name="optionalPrice"
						                label="Minimum price (in EUR)"
						                placeHolder="xxx" 
						                value={optionalPrice}
						                type="number"
						                onInputChange={(e) => setOptionalPrice(e.target.value)} 
				              		/>
				              		{optional_price_error && <div className="text-danger pt-1">Ticket Price is a required field</div>}
					            	</div>
					        	}

					            {packageType === "Variant" && <div className="w-100">
					              {differentPriceList.map((eachRow, index) => (
					                
					                <div className="row mb-3" key={index}>
					                  	<div className="d-flex align-items-center justify-content-between">
					                    	<h6 className="color-primary-50 heading-6">Price Option {index + 1} </h6>
					                    	<div>
					                      		<button type="button" className="ax-button" onClick={() => removeItem(eachRow, index)} disabled = {index === 0}>
					                        		<i className='bx bx-x-circle text-danger'></i>
					                      		</button>
					                    	</div>
					                  	</div>
					                  
						                <div className="col-lg-6 col-md-6">
						                    <Input
						                      	name="packageName"
						                      	label="Name"
						                      	placeHolder="xxx" 
						                      	value={eachRow.packageName}
						                      	onInputChange={(e) => onDifferentValueUpdate(e, index)} 
						                    />
						                    {variant_package_errors && variant_package_errors[index] && variant_package_errors[index]["packageName"] && <p className="pt-1 text-danger">Name is a required field</p>}
						                </div>

					                  	<div className="col-lg-6 col-md-6">
						                    <Input
						                    	name="packagePrice"
						                    	label="Ticket price (in EUR)"
						                     	placeHolder="xxx" 
						                      	value={eachRow.packagePrice}
						                      	type="number"
						                      	onInputChange={(e) => onDifferentValueUpdate(e, index)} 
						                    />
						                    {variant_package_errors && variant_package_errors[index] && variant_package_errors[index]["packagePrice"] && <p className="pt-1 text-danger">Price is a required field</p>}
						                
					                  	</div>
					                </div>
					              	))
					              	}
					              	<button className="tertiary_btn capitalize color-primary-50 mt-3 w-100" disabled={differentPriceList.length >= 9} onClick={addDifferentPrice}>Add more Pricing Options</button>
					            </div>
					            }
				            </div>
			            </div>
			        </div>
			    </div>
		    </div>
        </DialogContent>

        <DialogActions className="bg-white">
        	<div className="py-3">
	          <button autoFocus onClick={handleClose} className="tertiary_btn capitalize color-primary-50 mx-2">
	            Cancel
	          </button>

	          <button autoFocus onClick={onSubmit} className="secondary_btn capitalize">
	           {isEdit ? 'Update': 'Add'}
	          </button>
          </div>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

export default EventPackageDialog;
