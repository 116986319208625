import React, {useState, useEffect, useRef} from 'react';
import { useLocation, Link } from 'react-router-dom';
import {useDispatch} from 'react-redux';
import { Breadcrumb } from "rsuite";
import TabView from '../../../../../components/tabView/TabView';
import Table from '../../../../../components/table/Table';

import CustomizedSnackbars from  '../../../../../components/CustomizedSnackbars';
import HelmetDetails from "../../../../../components/HelmetDetails";
import Loading from '../../../../../components/loading/Loading';

import CatalystDetailsDialog from './catalystDetailsDialog/CatalystDetailsDialog';
import {getCatalystAll} from '../../../../../actions/catalysts.actions';
import {baseURL} from '../../../../../actions/Constants';

import {Catalyst_Tab_List} from '../../../../../utils/DefaultInitialValues';
import {getCurrentMonthText} from '../../../../../utils/util';

import right_arrow from '../../../../../assets/thin arrow - right.png';

const AllCatalysts = () => {
	const tableRef = useRef(null);

	const location = useLocation();
	const [crumb, setCrumb] = useState([]);
	const [activeTab, setActiveTab] = useState(0);
	const dispatch = useDispatch();
	const currentMonth = getCurrentMonthText();

	const getCatalystAllAction = getCatalystAll;

	const [allCatalysts, setAllCatalysts] = useState([]);
	const [allFilterCatalysts, setAllFilterCatalysts] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const [isError, setError] = useState(false);

	const [isShowDialog, setShowDialog] = useState(false);
	const [catlystDetails, setCatalystDetails] = useState({});


	const getCatalystById = async (userId = 0) => {
		setLoading(false);
		const URL = `${baseURL}/minded-user/getUserById/${userId}`;

       	return await fetch(URL)
       	.then(response => {
		    const resp = response;
		    const {status = ""} = resp;
		    setLoading(false);

		    if(status === 200) {
		      return response.json().then(res => {
		        setCatalystDetails(res);
		        setShowDialog(true);
		      });
		    } else {
		      	//Error
		    	setError(true);
		    }
		  })
		  .catch((error) => {
		  	setLoading(false);
		  	setError(true);
		    console.log('error getCatalystById', error);
		});
	}

    const getAllCatalsytMixedDetails = async () => {
    	setLoading(true);
		const URL=`${baseURL}/minded-services/admin/getAllCatalystBookingDetails`;
		
		return await fetch(URL)
			.then(response => {
			const resp = response;

			const {status = ""} = resp;
			setLoading(false);
			if(status === 200) {
			  return response.json().then(res => {
			    setAllCatalysts(res);
			  });
			} else {
			  //Error
			  setError(false);
			}
			})
			.catch((error) => {
			console.log('error getAllCatalsytMixedDetails', error);
			setError(false);
		});
    }
	
	const activeOptions = {
	    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
	      <div className="d-flex align-items-center justify-content-center">
	        <button className="btn btn-outline-warning mx-3" onClick = {() => handleHoldingClick(selectedRows)}>
	        	Holding
	        </button>

	        <button className="btn btn-outline-danger mx-3" onClick = {() => handleRejectedClick(selectedRows)}>
	        	Reject
	        </button>
	      </div>
	    ),

	    /*selectableRows: false,*/
	    onTableChange: (action, state) => {
	      
	    },
	    onRowsDelete: (rowsDeleted, newData) => {
	     
	    }
  	};

  
  	const pendingOptions = {
	    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
	      <div className="d-flex align-items-center justify-content-center">
	        <button className="btn btn-success mx-3" onClick = {() => handleApproveClick(selectedRows)}>
	    		Approve
	        </button>

	        <button className="btn btn-outline-danger mx-3" onClick = {() => handleRejectedClick(selectedRows)}>
	        	Reject
	        </button>
	      </div>
	    ),

	    /*selectableRows: false,*/
	    onTableChange: (action, state) => {
	      
	    },
	    onRowsDelete: (rowsDeleted, newData) => {
	     
	    }
  	};

  	const rejectOptions = {
	    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
	      <div className="d-flex align-items-center justify-content-center">
	        <button className="btn btn-success mx-3" onClick = {() => handleApproveClick(selectedRows)}>
	    		Approve
	        </button>

	        <button className="btn btn-outline-warning mx-3" onClick = {() => handleHoldingClick(selectedRows)}>
	        	Holding
	        </button>
	      </div>
	    ),

	    /*selectableRows: false,*/
	    onTableChange: (action, state) => {
	      
	    },
	    onRowsDelete: (rowsDeleted, newData) => {
	     
	    }
  	};

  	const [option, setOption] = useState(activeOptions);

	useEffect(() => {
        //let locationVal = location?.pathname;
        const crumbs = location.pathname.split("/");
        createCrumbDetails(crumbs);
    }, [location]);

    const changeOption = () => {
    	let newOption = activeOptions;
    	
    	switch(activeTab) {
    		case 0:
    			newOption = activeOptions;
    			break;
    		case 1:
    			newOption = pendingOptions;
    			break;
    		case 2:
    			newOption = rejectOptions;
    			break;
    		default : break;
    	}

    	setOption(newOption);
    }

    useEffect(() => {
    	applyFilter();
    }, [activeTab]);

    const applyFilter = () => {
    	let text = "active";

    	switch(activeTab) {
    		case 0:
    			text = "active";
    			break;
    		case 1:
    			text = "pending";
    			break;
    		case 2:
    			text = "blocked";
    			break;
    		default : break;
    	}

    	const filterData = allCatalysts.filter((item, index) => item.status === text);
    	setAllFilterCatalysts(filterData);
    }

    useEffect(() => {
    	changeOption();
    }, [allFilterCatalysts]);

  	const handleApproveClick = async (selectedRows) => {
		if(allFilterCatalysts && allFilterCatalysts.length > 0) {
	  		const filterArray = selectedRows?.data && selectedRows?.data.map((item, index) => allFilterCatalysts[item.index]);
	  		const finalList = filterArray.map((item, idx) => {
	  			const obj = {};
	  			obj.status = "active";
	    		obj.userId = item?.userId;
	    		return obj;
	  		});

	  		approveCatalyst(finalList, "active");
			
		}
	}

	const handleHoldingClick = async (selectedRows) => {
  		
  		if(allFilterCatalysts && allFilterCatalysts.length > 0) {
	  		const filterArray = selectedRows?.data && selectedRows?.data.map((item, index) => allFilterCatalysts[item.index]);
	  		const finalList = filterArray.map((item, idx) => {
	  			const obj = {};
	  			obj.status = "pending";
	    		obj.userId = item?.userId;
	    		return obj;
	  		});

	  		approveCatalyst(finalList, "pending");
		}
  	}

  	const handleRejectedClick = async (selectedRows) => {

		if(allFilterCatalysts && allFilterCatalysts.length > 0) {
	  		const filterArray = selectedRows?.data && selectedRows?.data.map((item, index) => allFilterCatalysts[item?.index]);
			const finalList = filterArray.map((item, idx) => {
	  			const obj = {};
	  			obj.status = "blocked";
	    		obj.userId = item?.userId;
	    		return obj;
	  		});
			approveCatalyst(finalList, "blocked");
		}
  	}

	const approveCatalyst = async (fullList = [], state = "active") => {
	    setLoading(true);

	    const URL =  `${baseURL}/minded-user/bulk-update`;
	    //const myHeaders = getAuthenticateHeader();
  		
	    return await fetch(URL, {
	      	method: 'PUT',
	      	body: JSON.stringify(fullList),
		    headers: {
		    	'Content-Type': 'application/json'
		    }
	    })
	    .then(response => {
	    	const resp = response;

	      	const {status = ""} = resp;

	      	setLoading(false);

	      	if(status === 201 || status === 200) {
		        getAllCatalsytMixedDetails();
		        document.querySelector("input.PrivateSwitchBase-input").click();
	      	} else {
	        	
	      	}
	    })
	    .catch((error) => {
	      console.log('error updateUser', error);
	      setLoading(false);
	    })
	}

	const onCatalystNameClick = (emailID = "") => {
		let item = {};
		const filterCatalystArray = allCatalysts.filter((item, index) => item?.emailId === emailID);
   		if(filterCatalystArray && filterCatalystArray.length > 0) {
   			item = filterCatalystArray[0];
   			getCatalystById(item.userId);
   		}
	}

	const TabPanel = (service_type = [], data = []) => {
		return (
			<>
			</>
		)
	}

    const columns = [
	    {
	      	name: "emailId",
	      	label: 'Name',
	      	options: {
	       		filter: true,
	       		sort: true,
	       		customBodyRender: (value, tableMeta, updateValue) => {
	       			let val = "-";
	       			let name = "";
	       			
	       			const filterCatalystArray = allCatalysts.filter((item, index) => item?.emailId === value);
			   		if(filterCatalystArray && filterCatalystArray.length > 0) {
			   			const item = filterCatalystArray[0];
			   			name = item?.name;
			   			val = item?.profileDescription?.publicName || val;
			   			
			   		}
	              	return (
		                <div className="ax-button color-primary-50">
		                	<div onClick={() => onCatalystNameClick(value)}>{name}</div>
		                  	{(val !== "-" && val !== name) && <div>({val})</div>}
		                </div>
	              	);
          		}
	      	}
	    },
	    {
	      	name: "emailId",
	      	label: 'Email',
	      	options: {
	       		filter: true,
	       		sort: true,
	       		customBodyRender: (value, tableMeta, updateValue) => {
	              	return (
		                <div className="ax-button color-primary-50">
		                  {value}
		                </div>
	              	);
          		}
	      	},
	    },
	    {
	      	name: "expertise",
	      	label: 'Expertise',
	      	options: {
	       		filter: true,
	       		sort: true,
	       		customBodyRender: (value, tableMeta, updateValue) => {
	              	return (
		                <div className="ax-button color-primary-50">
		                  {value?.length != 0 ? value.join(', ') : '-'}
		                </div>
	              	);
          		}
	      	}
	    },
	    {
	      	name: "subscription",
	      	label: 'Subscription',
	      	options: {
	       		filter: true,
	       		sort: true,
	       		customBodyRender: (value, tableMeta, updateValue) => {
	              	
	              	return (
		                <div className="ax-button color-primary-50">
		                  {value}
		                </div>
	              	);
          		}
	      	}
	    },
	    {
	      	name: "serviceCount",
	      	label: 'Offerings',
	      	options: {
	       		filter: true,
	       		sort: true,
	       		customBodyRender: (value, tableMeta, updateValue) => {
	              	return (
		                <div className="ax-button color-primary-50">
		                  {value}
		                </div>
	              	);
          		}
	      	}
	    },
	    {
	      	name: "revenue",
	      	label: `Earning in EUR`, /*Earning in ${currentMonth}.*/
	      	options: {
	       		filter: true,
	       		sort: true,
	       		customBodyRender: (value, tableMeta, updateValue) => {
	              	return (
		                <div className="ax-button color-primary-50">
		                  {value}
		                </div>
	              	);
          		}
	      	}
	    },
	    {
	      	name: "purchaseCount",
	      	label: 'Tickets sold',
	      	options: {
	       		filter: true,
	       		sort: true,
	       		customBodyRender: (value, tableMeta, updateValue) => {
	              	return (
		                <div className="ax-button color-primary-50">
		                  {value.package + value.plan + value.ticket}
		                </div>
	              	);
          		}
	      	}
	    }    
	];

	const createCrumbDetails = (crumbs) => {
		const crumbArray = [];
		
		crumbs.map((crmb, index) => {
			const obj = {};

			switch(crmb) {
				case "":
					obj.path = "/";
					obj.label = "Home";
					crumbArray.push(obj);
					break;
				case "dashboard":
					obj.path = "/dashboard";
					obj.label = "My Dashboard";
					crumbArray.push(obj);
					break;
				case "catalysts":
					obj.path = "/dashboard/catalysts";
					obj.label = "All Catalysts";
					crumbArray.push(obj);
					break;
				default : break;
			}

			setCrumb(crumbArray);
		});
	}

	useEffect(() => {
		applyFilter();
	}, [allCatalysts]);

	useEffect(() => {
		window.scrollTo({top: 0, behavior: "smooth"});
		getAllCatalsytMixedDetails();
	}, []);

	return (
		<div className="main-no-content-padding">
			<HelmetDetails title="All Catalysts - Admin Dashboard | Minded." />
			<Breadcrumb> 
                {crumb.map((item, index) => {
                	return (index !== crumb.length - 1) ? (
	                	<>
		                	<Link to={item.path}>
		                		<span className={`body-R color-secondary-100 px-2`}>{item?.label}</span>
		                	</Link>
		                	<img src={right_arrow} style={{width: '12px', height: '12px'}} alt />
	                	</>
	                	) : (<span className={`body-R color-neutral-50 px-2`}>{item?.label}</span>)
                	}
                )}
	        </Breadcrumb>

	        <h1 className="heading-1 color-primary-50">All Catalysts</h1>
			
			<div className="my-5">
				<div className="w-100">
					<TabView 
						children = {(service_Type) => TabPanel(service_Type)} 
						service_Type = {allFilterCatalysts} 
						tabs={Catalyst_Tab_List} 
						onTabClick = {(val) => setActiveTab(val)}
						data = {allFilterCatalysts}
					/>
				</div>
			</div>
			<div className="my-5">
				<Table ref = {tableRef} headers={columns} rowData = {allFilterCatalysts} options = {option} />
			</div>
			{isShowDialog && <CatalystDetailsDialog isOpen = {isShowDialog} details = {catlystDetails} handleClose = {() => setShowDialog(false)} />}
			{isLoading && <Loading />}
			{isError && <CustomizedSnackbars isShow={isError} text="Something went wrong. Please try after sometime." showAlert={(isHide) => setError(false)} />}
		</div>
	)
}

export default AllCatalysts;

