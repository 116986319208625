import React from "react";
import "./become-version.css";
import HorizontalTicker from "./HorizontalTicker";


 const BecomeVersionOfYourself = () => {
  return (
    <div className="become-version-container">
      <div className="become-version-image"></div>
      <div className="become-version-title-container">
        <span className="become-version-title">
          Become the best version of yourself.
        </span>

        <div className="become-version-description-container">
          <span className="become-version-description">
            Join a community crafted to help you thrive. Dive into expert-led
            classes, hands-on workshops, and dynamic events that transform your
            aspirations into achievements.
          </span>
        </div>
      </div>

      <div className="start-now-parent-container">
        <div className="start-now-container">

        <HorizontalTicker text="Unlock a 33% Lifetime Discount—Sign Up for the Quarterly Plan Today!" />
        </div>
      </div>
    </div>
  );
};

export default BecomeVersionOfYourself;
